import { format } from "date-fns"
import { useMemo } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import GenericItemsGroup from "@/components/grouping/generic-items-group.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { BirdsGroupsEventCardWrapper } from "@/features/events/birds-groups-events/components/birds-group-event-card/birds-group-event-card.tsx"
import { BirdsGroupEventsFilter } from "@/features/events/birds-groups-events/components/birds-groups-events-filter/birds-group-events-filter.tsx"
import { useBirdsGroupsEventsFilter } from "@/features/events/birds-groups-events/hooks/use-birds-groups-events-filter.ts"
import { EventsInfo } from "@/features/events/common/components/events-info/events-info.tsx"
import { NEW_BIRDS_GROUPS_EVENTS_VIEW } from "@/utils/constants/routes.ts"
import { groupItemsByTimeStampWithField } from "@/utils/helpers/time-grouping.ts"

const AllBirdsGroupsEventsView = () => {
  const { t } = useTranslation()
  const { filters, updateFilters, events, isLoading } =
    useBirdsGroupsEventsFilter()

  const groupedEvents = useMemo(
    () =>
      events?.length ? groupItemsByTimeStampWithField(events, "date") : null,
    [events],
  )

  if (isLoading) {
    return (
      <>
        <MobileTopBar title={t("birdsGroupsEvents")} />
        <LoadingAnimation />
      </>
    )
  }

  const handleTimeFrameChange = (timeFrame: DateRange | undefined) => {
    if (!timeFrame) {
      updateFilters({ ...filters, date_from: undefined, date_to: undefined })
      return
    }
    updateFilters({
      ...filters,
      date_from: timeFrame.from
        ? format(timeFrame.from, "yyyy-MM-dd")
        : undefined,
      date_to: timeFrame.to ? format(timeFrame.to, "yyyy-MM-dd") : undefined,
    })
  }

  const handleBirdsGroupChange = (birdsGroup: string) => {
    updateFilters({ ...filters, birds_group: birdsGroup })
  }

  const handleEventTypeChange = (eventType: string) => {
    updateFilters({ ...filters, event_type: eventType })
  }

  return (
    <>
      <MobileTopBar
        title={t("birdsGroupsEvents")}
        navigateTo={NEW_BIRDS_GROUPS_EVENTS_VIEW}
      />
      <PageContentWrapperLarge>
        <BirdsGroupEventsFilter
          filters={filters}
          onBirdsGroupChange={handleBirdsGroupChange}
          onSelectedTimeFrameChange={handleTimeFrameChange}
          onSelectedEventTypeChange={handleEventTypeChange}
        />
        <EventsInfo isLoading={isLoading} eventsCount={events?.length} />
        {groupedEvents ? (
          <GenericItemsGroup
            items={groupedEvents}
            ItemComponent={BirdsGroupsEventCardWrapper}
          />
        ) : (
          <NoResultsFound
            message={t("thereAreNoEventsYet")}
            className={"h-[calc(100%-120px)]"}
          />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllBirdsGroupsEventsView
