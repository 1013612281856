import { memo, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header"
import { SelectionRow } from "@/components/selection-row/selection-row"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer"
import { type Option } from "@/utils/types/misc-types"

interface Props<T extends boolean> {
  renderTrigger: React.ReactNode
  title?: string
  options: Option[]
  selectedValues: T extends true ? string[] : string
  onSelectionChange: (value: T extends true ? string[] : string) => void
  multiple?: T
  open?: boolean
  setOpen?: (open: boolean) => void
  reorderOptions?: boolean
}

const SelectionRowBase = <T extends boolean>({
  renderTrigger,
  title,
  options,
  selectedValues,
  onSelectionChange,
  multiple = false as T,
  open,
  setOpen,
  reorderOptions = false,
}: Props<T>) => {
  const { t } = useTranslation()

  const isSelected = useCallback(
    (value: string): boolean => {
      if (!multiple) return selectedValues === value
      return (selectedValues as string[]).includes(value)
    },
    [multiple, selectedValues],
  )

  const handleSelectionChange = (value: string) => {
    if (!multiple) {
      onSelectionChange(value as T extends true ? string[] : string)
      return
    }

    const currentValues = selectedValues as string[]
    const newValues = currentValues.includes(value)
      ? currentValues.filter((v) => v !== value)
      : [...currentValues, value]
    onSelectionChange(newValues as T extends true ? string[] : string)
  }

  const sortedOptions = useMemo(() => {
    if (!reorderOptions) return options

    return [
      ...options
        .filter((option) => isSelected(option.value))
        .sort((a, b) => a.label.localeCompare(b.label)),
      ...options
        .filter((option) => !isSelected(option.value))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ]
  }, [options, reorderOptions, isSelected])

  return (
    <Drawer open={open}>
      <DrawerTrigger asChild>{renderTrigger}</DrawerTrigger>
      <DrawerContent className="h-3/4 overflow-hidden">
        {title && (
          <GenericTypeDrawerHeader
            title={t(title)}
            onSaveClick={() => setOpen?.(false)}
          />
        )}
        <DrawerContentWrapper className="pb-5">
          {sortedOptions.map((option) => (
            <SelectionRow
              key={option.value}
              selectionMode={multiple ? "multiple" : "single"}
              option={option}
              isSelected={isSelected(option.value)}
              onSelectionChange={() => handleSelectionChange(option.value)}
            />
          ))}
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}

export const SelectionRowDrawer = memo(
  SelectionRowBase,
) as typeof SelectionRowBase
