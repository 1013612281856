import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { useGetAllBirdsGroupsEventTypesQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-types-api.ts"
import { type BirdsGroupsEvent } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { BIRDS_GROUPS_EVENTS_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"

interface Props {
  event: BirdsGroupsEvent
}

const BirdsGroupsEventCardWrapper: React.FC<{ item: ItemsArray[number] }> = ({
  item,
}) => {
  return <BirdsGroupsEventCard event={item as BirdsGroupsEvent} />
}

BirdsGroupsEventCardWrapper.displayName = "HiveEventCardWrapper"

const BirdsGroupsEventCard: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, isLoading, isError } = useGetAllBirdsGroupsEventTypesQuery()

  const navigateToEvent = () => {
    navigate(BIRDS_GROUPS_EVENTS_SCREEN_PATH + `/${event.id}`)
  }

  const selectedEventType = data && data.find((type) => type.id === event.type)

  if (isLoading || isError) {
    return (
      <Skeleton
        className={"min-h-[60px] w-full rounded-[var(--fp-border-radius)]"}
      />
    )
  }

  return (
    <div
      onClick={navigateToEvent}
      className="box-border flex min-h-[60px] w-full items-center justify-between gap-2.5 rounded-[--fp-border-radius] border border-neutral-200 bg-white px-[15px] py-[7px]"
    >
      <div className={"grid place-content-center"}>
        {selectedEventType?.picture ? (
          <img
            src={selectedEventType.picture}
            alt={"eventPicture"}
            className={"h-[30px] w-[30px] rounded-[8px] bg-brown-500 p-1"}
          />
        ) : (
          <PlaceholderIcon />
        )}
      </div>
      <div className="flex h-10 flex-1 flex-col justify-between">
        <div
          className={
            "grid grid-cols-[auto_auto] items-center justify-between gap-[10px]"
          }
        >
          <div className={"truncate text-body-sm"}>
            {selectedEventType && t(selectedEventType.name)}
          </div>
          <div>{format(new Date(event.date), "dd.MM.yyyy")}</div>
        </div>
        <div className="flex items-start justify-start gap-[5px] text-[--dark-grey] opacity-50">
          <p className={"font-semibold opacity-80"}>{t("birdsGroup")}</p>
          <p>{event?.birds_group_name}</p>
        </div>
      </div>
    </div>
  )
}

BirdsGroupsEventCard.displayName = "BirdsGroupsEventCard"

export { BirdsGroupsEventCard, BirdsGroupsEventCardWrapper }
