import React from "react"
import { useTranslation } from "react-i18next"
import { useParams, useSearchParams } from "react-router-dom"
import UserIcon from "@/assets/icons/account/user-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import NoteThumbnailIcon from "@/assets/icons/notes/note-thumbnail-icon.tsx"
import BirdIcon from "@/assets/svg/bird-icon.svg?react"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { useGetBirdsGroupsQuery } from "@/features/birds-groups/api/birds-groups-api.ts"
import EventsTypeDrawer from "@/features/events/animal-events/components/event-type-drawer/event-type-drawer.tsx"
import { BirdsGroupEventMovementEditor } from "@/features/events/birds-groups-events/components/birds-group-event-movement-editor/birds-group-event-movement-editor.tsx"
import { BirdsGroupEventsMeasurementEditor } from "@/features/events/birds-groups-events/components/birds-group-measurements-events/birds-group-events-measurement-editor.tsx"
import { BirdsGroupsTreatmentEvent } from "@/features/events/birds-groups-events/components/birds-groups-treatment-event/birds-groups-treatment-event.tsx"
import { useBirdsEventTypes } from "@/features/events/birds-groups-events/hooks/use-birds-event-types.ts"
import useCheckBirdsGroupEventType from "@/features/events/birds-groups-events/hooks/use-check-birds-group-event-type.ts"
import {
  BIRDS_GROUPS_CONSTS,
  BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM,
  BIRDS_GROUPS_MOVEMENT_EVENTS_ENUM,
  BIRDS_GROUPS_TREATMENT_EVENTS_ENUM,
} from "@/features/events/birds-groups-events/types/birds-groups-events-consts.ts"
import { type BirdsGroupEventInput } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface Props {
  birdsGroupsEvent: BirdsGroupEventInput
  onBirdsGroupsEventChange: (birdsGroupsEvent: BirdsGroupEventInput) => void
}

export const BirdsGroupEventEditor: React.FC<Props> = ({
  birdsGroupsEvent,
  onBirdsGroupsEventChange,
}) => {
  const { t } = useTranslation()
  const { data: birdsGroups } = useGetBirdsGroupsQuery()
  const checkEventType = useCheckBirdsGroupEventType()
  const eventTypes = useBirdsEventTypes(birdsGroupsEvent)

  const [searchParams] = useSearchParams()
  const { birdsGroupEventId } = useParams()
  const birdsGroupId = searchParams.get(BIRDS_GROUPS_CONSTS.BIRDS_GROUP_ID)
  const searchParamsEventId = searchParams.get(
    BIRDS_GROUPS_CONSTS.BIRDS_GROUP_EVENT_ID,
  )

  if (searchParamsEventId && searchParamsEventId !== birdsGroupsEvent.type) {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      type: searchParamsEventId,
      event_data: {
        measurement_type: BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING,
      },
    })
  }

  const isTreatmentEvent =
    checkEventType(
      birdsGroupsEvent.type,
      BIRDS_GROUPS_TREATMENT_EVENTS_ENUM.MEDICATION_ADMINISTRATION,
    ) ||
    checkEventType(
      birdsGroupsEvent.type,
      BIRDS_GROUPS_TREATMENT_EVENTS_ENUM.HEALTH_CHECK,
    )

  const isHeadAdditionEvent = checkEventType(
    birdsGroupsEvent.type,
    BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.ADD_HEADS,
  )

  const isEggCountingEvent = checkEventType(
    birdsGroupsEvent.type,
    BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING,
  )

  const isHeadRemovalEvent = checkEventType(
    birdsGroupsEvent.type,
    BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.REMOVE_HEADS,
  )

  const isTransferEvent = checkEventType(
    birdsGroupsEvent.type,
    BIRDS_GROUPS_MOVEMENT_EVENTS_ENUM.TRANSFER,
  )

  const getAssociatedBirdsGroupEventTypeDescription = () => {
    if (!eventTypes) return ""
    const selectedEventType = Array.from(eventTypes.values())
      .flat()
      .find((a) => a.id == birdsGroupsEvent.type)
    return t(selectedEventType?.description ?? "")
  }

  const birdsGroupsOptions =
    (birdsGroups &&
      birdsGroups.map((birdsGroup) => ({
        value: birdsGroup.id,
        label: birdsGroup.name,
      }))) ||
    []

  const handleEventTypeChange = (eventType: string | undefined) => {
    if (!eventType) return

    let draftEventData = birdsGroupsEvent.event_data || {}
    if (checkEventType(eventType, BIRDS_GROUPS_MOVEMENT_EVENTS_ENUM.TRANSFER)) {
      draftEventData = {
        from_location: selectedBirdsGroup?.location,
        to_location: "",
      }
    }
    if (
      checkEventType(eventType, BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.ADD_HEADS)
    ) {
      draftEventData = {
        measurement_type: BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.ADD_HEADS,
      }
    }
    if (
      checkEventType(
        eventType,
        BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.REMOVE_HEADS,
      )
    ) {
      draftEventData = {
        measurement_type: BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.REMOVE_HEADS,
      }
    }
    if (
      checkEventType(
        eventType,
        BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING,
      )
    ) {
      draftEventData = {
        measurement_type: BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING,
      }
    }
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      type: eventType,
      event_data: draftEventData,
    })
  }

  const handleDateChange = (date: string) => {
    onBirdsGroupsEventChange({ ...birdsGroupsEvent, date })
  }

  const handlePersonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      person_name: event.target.value,
    })
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      description: event.target.value,
    })
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onBirdsGroupsEventChange({ ...birdsGroupsEvent, notes: event.target.value })
  }

  const selectedBirdsGroup = birdsGroups?.find(
    (a) => a.id === birdsGroupsEvent.birds_group,
  )

  const handleFromPositionChange = (fromPosition: string) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      event_data: {
        ...birdsGroupsEvent.event_data,
        from_location: fromPosition,
      },
    })
  }

  const handleToPositionChange = (toPosition: string) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      event_data: {
        ...birdsGroupsEvent.event_data,
        to_location: toPosition,
      },
    })
  }

  const setMedication = (medication: string) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      event_data: {
        ...birdsGroupsEvent.event_data,
        medication,
      },
    })
  }

  const setDosage = (dosage: string) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      event_data: {
        ...birdsGroupsEvent.event_data,
        dosage,
      },
    })
  }

  const handleMeasurementChange = (value: string) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      event_data: {
        ...birdsGroupsEvent.event_data,
        value,
      },
    })
  }

  const handleUnitChange = (unit: string) => {
    onBirdsGroupsEventChange({
      ...birdsGroupsEvent,
      event_data: {
        ...birdsGroupsEvent.event_data,
        unit,
      },
    })
  }

  const countType = isEggCountingEvent ? "eggs" : "heads"

  return (
    <div className={"flex flex-col gap-[10px]"}>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("generalEventDetails")}
      </div>
      <OverflowMenuItem isDisabled={!!birdsGroupEventId || !!birdsGroupId}>
        <SelectionRowDrawer
          title={t("selectBirdsGroup")}
          multiple={false}
          selectedValues={birdsGroupsEvent.birds_group}
          options={birdsGroupsOptions}
          onSelectionChange={(value) =>
            onBirdsGroupsEventChange({
              ...birdsGroupsEvent,
              birds_group: value,
            })
          }
          renderTrigger={
            <div className={"flex items-center gap-[10px]"}>
              <BirdIcon className="h-[30px] w-[30px] rounded bg-amber-500 p-1 text-white" />
              {!birdsGroupsEvent.birds_group && <RequiredIndicator />}
              {birdsGroupsEvent.birds_group ? (
                <p className={"text-brown-500"}>{selectedBirdsGroup?.name}</p>
              ) : (
                <p className={"text-[14px]"}>{t("selectBirdsGroup")}</p>
              )}
            </div>
          }
        />
      </OverflowMenuItem>
      {eventTypes ? (
        <OverflowMenuItem
          isDisabled={!!birdsGroupEventId || !!searchParamsEventId}
        >
          <EventsTypeDrawer
            addEventEnabled={false}
            eventTypesMap={eventTypes}
            fallbackText={t("selectEventType")}
            onEventSelect={handleEventTypeChange}
            selectedValue={birdsGroupsEvent.type}
          />
        </OverflowMenuItem>
      ) : (
        <Skeleton className={"h-[56px] w-full rounded-[8px]"} />
      )}
      {birdsGroupsEvent.type && (
        <OverflowMenuItem className={"text-[14px] text-neutral-500"}>
          <i>{t("eventInfoDescription")}</i>
          {getAssociatedBirdsGroupEventTypeDescription()}
        </OverflowMenuItem>
      )}
      <GenericDatePicker
        isDisabled={!!birdsGroupEventId}
        value={birdsGroupsEvent.date}
        onChange={handleDateChange}
      />
      <InputFieldWrapperWithIcon
        icon={<UserIcon size={30} />}
        align={"row"}
        name={t("personName")}
        isDisabled={false}
        value={birdsGroupsEvent.person_name}
        onChange={handlePersonNameChange}
      />
      {isTransferEvent && (
        <BirdsGroupEventMovementEditor
          draftEvent={birdsGroupsEvent}
          onFromPositionChange={handleFromPositionChange}
          onToPositionChange={handleToPositionChange}
        />
      )}
      {isTreatmentEvent && (
        <BirdsGroupsTreatmentEvent
          draftEvent={birdsGroupsEvent}
          onMedicationChange={setMedication}
          onDosageChange={setDosage}
          onUnitChange={handleUnitChange}
        />
      )}
      {(isHeadAdditionEvent || isHeadRemovalEvent || isEggCountingEvent) && (
        <BirdsGroupEventsMeasurementEditor
          countType={countType}
          draftEvent={birdsGroupsEvent}
          onValueChange={handleMeasurementChange}
        />
      )}
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("description")}
      </div>
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={birdsGroupsEvent.description}
        onChange={handleDescriptionChange}
      />
      <TextAreaFieldWrapperWithIcon
        icon={<NoteThumbnailIcon size={30} />}
        name={t("otherObservations")}
        isReadOnly={false}
        value={birdsGroupsEvent.notes}
        onChange={handleNotesChange}
      />
    </div>
  )
}
