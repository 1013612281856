import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddEventIcon } from "@/assets/widgets/add-event-icon.tsx"
import { HiveEventCard } from "@/features/events/hive-events/components/hive-event-card/hive-event-card.tsx"
import { useFilterHiveEvents } from "@/features/events/hive-events/hooks/use-filter-hive-events.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"
import {
  HIVE_EVENTS_ROUTE,
  NEW_HIVE_EVENT_ROUTE,
} from "@/utils/constants/routes.ts"

interface Props {}

export const HiveEventsSummaryCard: React.FC<Props> = ({}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { events } = useFilterHiveEvents()

  const addItemsGoToAction =
    events && events?.length > 0
      ? () => navigate(NEW_HIVE_EVENT_ROUTE)
      : undefined
  return (
    <div
      className={
        "widget-border box-border flex w-full flex-col gap-5 bg-white px-4 py-4"
      }
    >
      <WidgetHeader
        title={t("hiveEvents")}
        navigateTo={HIVE_EVENTS_ROUTE}
        icon={<AddEventIcon />}
        addItemGoTo={addItemsGoToAction}
      />
      {events && events.length > 0 ? (
        <div className={"flex flex-col gap-2"}>
          {events.slice(0, 3).map((event, index) => (
            <HiveEventCard event={event} key={index} />
          ))}
        </div>
      ) : (
        <EmptyContentCreateItemButton navigateTo={NEW_HIVE_EVENT_ROUTE} />
      )}
    </div>
  )
}
