import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"
import NrBeesBeginningIcon from "@/assets/icons/bees/nr-bees-beginning-icon.tsx"
import QueenIcon from "@/assets/icons/bees/queen-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { useGetApiaryByIdQuery } from "@/features/bees/api/apiaries-api.ts"
import { type HiveResponse } from "@/features/bees/types/hives.ts"
import { getImageForHive } from "@/features/bees/utils/apiaries-utils.ts"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  hive: HiveResponse
}

export const HiveCardLong: React.FC<Props> = ({ hive }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    data: apiary,
    isLoading,
    isFetching,
  } = useGetApiaryByIdQuery(hive.apiary)
  const navigateToHive = () => {
    navigate(
      `${APIARIES_SCREEN_PATH}/${hive.apiary}${ALL_HIVES_PATH}/${hive.id}/`,
    )
  }

  if (isFetching || isLoading) {
    return (
      <Skeleton
        className={
          "widget-border h-[94px] w-full flex-shrink-0 rounded-[var(--fp-border-radius)]"
        }
      />
    )
  }

  return (
    <div
      onClick={navigateToHive}
      className="widget-border grid h-[94px] w-full grid-cols-[70px_auto] gap-2 rounded-[var(--fp-border-radius)] px-4 py-3"
    >
      <div className="bg-hive relative h-[70px] w-[70px] overflow-hidden rounded-[12px] border border-brown-500">
        <GenericPicture
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          size={50}
          src={getImageForHive(hive)}
          alt={"hiveImage"}
        />
      </div>
      <div className="grid w-full grid-cols-2 gap-2">
        <div className="flex flex-col justify-between">
          <div className="truncate text-[16px] font-semibold text-neutral-700">
            {hive.code}
          </div>
          {hive.queen ? (
            <div className="flex w-fit max-w-full items-center gap-2 truncate rounded-sm border border-amber-500 px-3 py-1.5 text-sm font-medium text-neutral-600">
              <QueenIcon className="h-5 w-5 flex-shrink-0 text-amber-500" />
              <span className="truncate">{hive.queen.name}</span>
            </div>
          ) : (
            <div className="flex w-fit items-center gap-2 rounded-sm bg-neutral-200 px-3 py-1.5 text-sm font-medium text-neutral-600">
              <span className="truncate">{t("noQueen")}</span>
            </div>
          )}
        </div>

        <div className="flex flex-col items-end justify-between">
          <div className="flex items-center gap-2 truncate rounded-sm border px-3 py-1.5 font-medium">
            <NrBeesBeginningIcon className="flex-shrink-0 text-brown-500" />
            <span className="truncate text-neutral-500">{hive.bee_count}</span>
          </div>
          {apiary && (
            <div className="flex w-fit max-w-full items-center gap-2 truncate rounded-sm bg-brown-50 px-3 py-1.5 font-medium text-neutral-500">
              <ApiariesIcon className="flex-shrink-0 scale-75 text-brown-500" />
              <span className="truncate text-neutral-500">{apiary.name}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
