import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { HIVES_FILTER_ENUM } from "@/features/bees/utils/hive-filter.ts"

export const useGetApiariesOptions = () => {
  const { data: apiaries } = useGetApiariesQuery()

  return [
    {
      value: HIVES_FILTER_ENUM.ALL_APIARIES,
      label: HIVES_FILTER_ENUM.ALL_APIARIES,
    },
    ...(apiaries
      ? apiaries.map((apiary) => ({
          value: apiary.id,
          label: apiary.name,
        }))
      : []),
  ]
}
