import React, { type ChangeEvent } from "react"
import { InputType } from "@/utils/enums/input-type-enum.ts"
import { cn } from "@/utils/styles.ts"

interface Props {
  count: number
  onCountChange: (count: number) => void
  iconSet: React.ReactNode
}

const MIN_NR_OF_BIRDS = 0
const MAX_NR_OF_BIRDS = 9999

export const BirdsCounter: React.FC<Props> = ({
  count,
  onCountChange,
  iconSet,
}) => {
  const handleIncreaseCount = () => {
    onCountChange(count + 1)
  }

  const handleDecreaseCount = () => {
    if (count <= 0) return
    onCountChange(count - 1)
  }

  const handleCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    if (inputValue.match(/^0\d/)) {
      event.target.value = inputValue.replace(/^0+/, "")
      inputValue = event.target.value
    }

    const numValue = parseInt(inputValue)
    if (isNaN(numValue)) return
    if (numValue < MIN_NR_OF_BIRDS || numValue > MAX_NR_OF_BIRDS) return

    onCountChange(numValue)
  }

  return (
    <div
      className={
        "widget-border box-border flex w-full flex-col gap-3 bg-white px-3 py-4"
      }
    >
      <div
        className={"flex items-center gap-2 border-b border-b-gray-100 pb-2"}
      >
        {iconSet}
      </div>
      <div className={"mx-8 grid h-16 grid-cols-[25%_50%_25%]"}>
        <button
          onClick={handleDecreaseCount}
          className={cn(
            "grid h-full w-full place-content-center rounded-[8px] border-[1.5px] border-amber-600 text-body-xl font-semibold text-amber-600",
            count === 0 && "opacity-50 grayscale",
          )}
        >
          -
        </button>
        <input
          type={InputType.NUMBER}
          value={count}
          min={0}
          max={9999}
          onChange={handleCountChange}
          className={cn(
            "border-b border-b-gray-100 pb-2 text-center text-body-xl text-neutral-700",
            count === 0 && "text-red-500",
          )}
        />
        <button
          onClick={handleIncreaseCount}
          className={
            "grid h-full w-full place-content-center rounded-[8px] border-[1.5px] border-amber-600 text-body-xl font-semibold text-amber-600"
          }
        >
          +
        </button>
      </div>
    </div>
  )
}
