import React from "react"
import { useTranslation } from "react-i18next"
import TreatmentsCountIcon from "@/assets/icons/ruminant-details/treatments-count-icon.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import { type BirdsGroupEventInput } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { useGetAllBirdsMedicationTypesQuery } from "@/features/events/common/api/birds-medication-types-api.ts"
import { medicationUnitOptions } from "@/features/events/common/types/common-events-types.ts"
import { HiveMedicationTypeDrawer } from "@/features/events/hive-events/components/hive-medication-drawer/hive-medication-drawer.tsx"

interface Props {
  draftEvent: BirdsGroupEventInput
  onMedicationChange: (medication: string) => void
  onDosageChange: (dosage: string) => void
  onUnitChange: (unit: string) => void
}

export const BirdsGroupsTreatmentEvent: React.FC<Props> = ({
  draftEvent,
  onMedicationChange,
  onDosageChange,
  onUnitChange,
}) => {
  const { t } = useTranslation()
  const { data: medications } = useGetAllBirdsMedicationTypesQuery()
  const selectedMedication =
    medications &&
    Object.values(medications).find(
      (medication) => medication.id === draftEvent.event_data?.medication,
    )

  const setMedication = (medication: string | undefined) => {
    if (medication) onMedicationChange(medication)
  }

  const setDosage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDosageChange(event.target.value)
  }

  if (!draftEvent.event_data?.unit && selectedMedication) {
    onUnitChange(selectedMedication.unit)
  }

  const getSelectedUnit = () => {
    if (draftEvent.event_data?.unit) {
      return draftEvent.event_data?.unit
    }

    if (selectedMedication) {
      return selectedMedication.unit
    }

    return ""
  }

  return (
    <>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("specificEventDetails")}
      </div>
      <OverflowMenuItem>
        {medications && (
          <HiveMedicationTypeDrawer
            selectedValue={draftEvent.event_data?.medication}
            onEventSelect={setMedication}
            medications={medications}
            fallbackText={"selectMedication"}
          />
        )}
      </OverflowMenuItem>
      {selectedMedication && (
        <OverflowMenuItem className={"text-[14px] text-neutral-500"}>
          <i>{t("medicineDescription")}</i>
          {t(selectedMedication?.description)}
        </OverflowMenuItem>
      )}
      {selectedMedication && (
        <div className={"grid grid-cols-[auto_auto] gap-2"}>
          <InputFieldWrapperWithIcon
            icon={<TreatmentsCountIcon />}
            align={"row"}
            name={t("dosage")}
            maxLength={14}
            inputMode={"numeric"}
            isDisabled={!draftEvent.event_data?.medication}
            value={draftEvent.event_data?.dosage}
            isRequired={!draftEvent.event_data?.dosage}
            onChange={setDosage}
          />
          <SelectionRowDrawer
            multiple={false}
            title={t("measuringUnit")}
            renderTrigger={
              <OverflowMenuItem
                className={
                  "grid place-content-center text-[14px] text-neutral-700"
                }
              >
                {t(getSelectedUnit())}
              </OverflowMenuItem>
            }
            options={medicationUnitOptions}
            selectedValues={getSelectedUnit()}
            onSelectionChange={onUnitChange}
          />
        </div>
      )}
    </>
  )
}
