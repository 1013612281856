import React from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { FilterScroll } from "@/components/filter-scroll/filter-scroll.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import { HIVES_FILTER_ENUM } from "@/features/bees/utils/hive-filter.ts"
import { CommonEventsDrawer } from "@/features/events/common/components/common-events-drawer/common-events-drawer.tsx"
import { DateFilterDrawer } from "@/features/events/common/components/date-filter-drawer/date-filter-drawer.tsx"
import { useGetApiariesOptions } from "@/features/events/common/hooks/use-get-apiaries-options.ts"
import { useGetApiariesEventTypesMap } from "@/features/events/common/hooks/use-get-event-types-map.ts"

interface ApiaryEventsFilterProps {
  selectedApiary: string
  onSelectedApiaryChange: (apiary: string) => void
  selectedTimeFrame: DateRange | undefined
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
  selectedEventType: string
  onSelectedEventTypeChange: (eventType: string) => void
}

export const ApiaryEventsFilter: React.FC<ApiaryEventsFilterProps> = ({
  selectedApiary,
  onSelectedApiaryChange,
  selectedEventType,
  onSelectedEventTypeChange,
  onSelectedTimeFrameChange,
  selectedTimeFrame,
}) => {
  const apiariesOptions = useGetApiariesOptions()
  const { t } = useTranslation()
  const parentEventTypes = useGetApiariesEventTypesMap()

  const handleApiaryChange = (apiaryId: string) => {
    onSelectedApiaryChange(apiaryId)
  }

  const handleEventTypeChange = (eventType: string | undefined) => {
    onSelectedEventTypeChange(eventType || "")
  }

  const selectedApiaryOption = apiariesOptions.find(
    (option) => option.value === selectedApiary,
  )

  return (
    <FilterScroll>
      <SelectionRowDrawer
        multiple={false}
        renderTrigger={
          <DrawerTrigger>
            <SelectPill
              isActive={selectedApiary !== HIVES_FILTER_ENUM.ALL_APIARIES}
            >
              <div>
                {selectedApiaryOption
                  ? t(selectedApiaryOption?.label)
                  : t(apiariesOptions[0].label)}
              </div>
            </SelectPill>
          </DrawerTrigger>
        }
        title={t("selectApiary")}
        options={apiariesOptions}
        selectedValues={selectedApiary}
        onSelectionChange={handleApiaryChange}
      />
      <CommonEventsDrawer
        eventTypesMap={parentEventTypes}
        fallbackText={t("selectEventType")}
        onEventSelect={handleEventTypeChange}
        selectedValue={selectedEventType}
      />
      <DateFilterDrawer
        selectedTimeFrame={selectedTimeFrame}
        onSelectedTimeFrameChange={onSelectedTimeFrameChange}
      />
    </FilterScroll>
  )
}
