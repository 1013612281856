import { useTranslation } from "react-i18next"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetAllQueensQuery } from "@/features/bees/api/queens-api.ts"
import { QueenCard } from "@/features/bees/components/queen-card/queen-card.tsx"
import { NEW_QUEEN_ROUTE } from "@/utils/constants/routes.ts"

export const AllQueensView = () => {
  const { data: queens, isLoading } = useGetAllQueensQuery()
  const { t } = useTranslation()

  return (
    <>
      <MobileTopBar title={t("allQueens")} navigateTo={NEW_QUEEN_ROUTE} />
      <PageContentWrapperLarge className={"flex flex-col"}>
        {isLoading && <LoadingAnimation customStyle={{ height: "inherit" }} />}
        {!isLoading && queens?.length === 0 && (
          <NoResultsFound
            className={"h-full"}
            image={"hive"}
            message={t("noQueensFoundForGivenQuery")}
          />
        )}
        {queens?.map((queen, index) => <QueenCard queen={queen} key={index} />)}
      </PageContentWrapperLarge>
    </>
  )
}
