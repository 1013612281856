export interface BirdsGroups {
  id: string
  name: string
  description: string
  picture: string
  count: number
  species: string
  breed: string
  age_group: string
  location: string
  intended_use: string
  notes: string[]
  tasks: string[]
  created_at: string
  updated_at: string
}

export enum AGE_GROUPS {
  ADULT = "Adult",
  JUVENILE = "Juvenile",
  HATCHLING = "Hatchling",
  MIXTURE = "Mixture",
}

export const AGE_GROUPS_OPTIONS = [
  { value: AGE_GROUPS.ADULT, label: "Adult" },
  { value: AGE_GROUPS.JUVENILE, label: "Juvenile" },
  { value: AGE_GROUPS.HATCHLING, label: "Hatchling" },
  { value: AGE_GROUPS.MIXTURE, label: "Mixture" },
]

export const AGE_GROUPS_DESCRIPTIONS = {
  [AGE_GROUPS.ADULT]: "birdGroupAdultDescription",
  [AGE_GROUPS.JUVENILE]: "birdGroupJuvenileDescription",
  [AGE_GROUPS.HATCHLING]: "birdGroupHatchingDescription",
  [AGE_GROUPS.MIXTURE]: "birdGroupMixtureDescription",
}

export type BirdsGroupInput = Omit<BirdsGroups, "id">

export const DEFAULT_BIRDS_GROUP: BirdsGroupInput = {
  name: "",
  description: "",
  picture: "",
  count: 0,
  species: "",
  breed: "",
  location: "",
  intended_use: "",
  notes: [],
  tasks: [],
  age_group: AGE_GROUPS.MIXTURE,
  created_at: "",
  updated_at: "",
}

export const BIRDS_SPECIES_ENUM = {
  CHICKEN: "Chicken",
  DUCK: "Duck",
  TURKEY: "Turkey",
  GOOSE: "Goose",
  OSTRICH: "Ostrich",
} as const

export type BIRDS_SPECIES_ENUM_TYPE =
  (typeof BIRDS_SPECIES_ENUM)[keyof typeof BIRDS_SPECIES_ENUM]

export enum BIRDS_INTENDED_USE {
  MEAT = "Meat",
  EGGS = "Eggs",
  EM = "Eggs and Meat",
  BREEDING = "Breeding",
  PET = "Pet",
  OTHER = "Other",
}

export const birdsIntendedUseOptions = Object.entries(BIRDS_INTENDED_USE).map(
  ([_, label]) => ({
    value: label,
    label,
  }),
)
