import { useTranslation } from "react-i18next"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperSmall from "@/components/page-content-wrappers/page-content-wrapper-small.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetBirdsGroupsQuery } from "@/features/birds-groups/api/birds-groups-api.ts"
import { BirdsGroupCard } from "@/features/birds-groups/components/birds-group-card/birds-group-card.tsx"
import { NEW_BIRDS_GROUP_SCREEN_PATH } from "@/utils/constants/routes.ts"

const AllBirdsGroupsView = () => {
  const { t } = useTranslation()
  const { data: birdsGroups, isError, isLoading } = useGetBirdsGroupsQuery()

  const showContent =
    !isError && !isLoading && birdsGroups && birdsGroups?.length > 0

  return (
    <>
      <MobileTopBar
        title={t("birdGroups")}
        navigateTo={NEW_BIRDS_GROUP_SCREEN_PATH}
      />
      <PageContentWrapperSmall className={"flex flex-col gap-2.5"}>
        {showContent ? (
          <>
            {birdsGroups?.map((birdGroup, index) => (
              <BirdsGroupCard key={index} group={birdGroup} />
            ))}
          </>
        ) : (
          <NoResultsFound
            className={"h-full"}
            message={t("noBirdsGroupsFound")}
          />
        )}
      </PageContentWrapperSmall>
    </>
  )
}

export default AllBirdsGroupsView
