import { toast } from "react-toastify"
import * as Yup from "yup"
import { type HiveEventInput } from "@/features/events/hive-events/api/hive-events-api.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

const baseEventSchema = {
  birds_group: Yup.string().required("birdsGroupRequired"),
  type: Yup.string().required("eventTypeRequired"),
  person_name: Yup.string()
    .max(255, "personNameMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .required("dateRequiredEvent"),
  description: Yup.string()
    .max(1000, "descriptionMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  notes: Yup.string()
    .max(1000, "notesMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
}

export const validateBirdsGroupEvent = async (
  birdsGroupEvent: Partial<HiveEventInput> = {},
) => {
  try {
    await Yup.object()
      .shape(baseEventSchema)
      .validate(birdsGroupEvent, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
