import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import { HIVES_FILTER_ENUM } from "@/features/bees/utils/hive-filter.ts"

export const useGetHivesOptions = () => {
  const { data: hives } = useGetAllHivesQuery()

  return [
    {
      value: HIVES_FILTER_ENUM.ALL_HIVES,
      label: HIVES_FILTER_ENUM.ALL_HIVES,
    },
    ...(hives
      ? hives.map((hive) => ({
          value: hive.id,
          label: hive.code,
        }))
      : []),
  ]
}
