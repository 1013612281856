import { useGetBirdsGroupByIdQuery } from "@/features/birds-groups/api/birds-groups-api.ts"
import { BIRDS_INTENDED_USE } from "@/features/birds-groups/types/birds-groups.ts"
import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { useGetAllBirdsGroupsEventTypesQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-types-api.ts"
import { BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM } from "@/features/events/birds-groups-events/types/birds-groups-events-consts.ts"
import { type BirdsGroupEventInput } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"
import usePrefetchImages from "@/hooks/use-prefetch-images.ts"

export const useBirdsEventTypes = (birdEvent: BirdsGroupEventInput) => {
  const { data } = useGetAllBirdsGroupsEventTypesQuery()
  const { data: birdGroup } = useGetBirdsGroupByIdQuery(birdEvent.birds_group)

  const getBirdsGroupsEventTypes = () => {
    const isEggProducerBirdGroup =
      birdGroup?.intended_use == BIRDS_INTENDED_USE.EGGS ||
      birdGroup?.intended_use == BIRDS_INTENDED_USE.EM

    if (isEggProducerBirdGroup) {
      return data
    } else {
      return data?.filter(
        (a) => a.name !== BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING,
      )
    }
  }

  const eventTypesMap = getBirdsGroupsEventTypes()?.reduce<
    Record<string, CommonEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})
  const eventTypes = useMapEventTypesToParents(eventTypesMap)

  const imagesArray = getBirdsGroupsEventTypes()
    ?.map((eventType) => eventType.picture)
    .filter(Boolean)
  usePrefetchImages(imagesArray)

  return eventTypes
}
