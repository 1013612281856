import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./events-table.module.css"
import { AnimalEventsTableRow } from "@/features/events/animal-events/components/animal-events-table-row/animal-events-table-row.tsx"
import { type GenericAnimalEvent } from "@/features/events/animal-events/types/animal-events.ts"
import { ApiaryEventsTableRow } from "@/features/events/apiary-events/components/apiary-events-table-row/apiary-events-table-row.tsx"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import { BirdsGroupsEventsTableRow } from "@/features/events/birds-groups-events/components/birds-events-widget/birds-groups-event-table-row.tsx"
import { type BirdsGroupsEvent } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"
import { type BaseEvent } from "@/features/events/common/types/common-events-types.ts"
import { HiveEventsTableRow } from "@/features/events/hive-events/components/hive-events-widget/hive-event-table-row.tsx"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"

type AllEventTypes =
  | GenericAnimalEvent[]
  | ApiaryEvent[]
  | HiveEvent[]
  | BirdsGroupsEvent[]

interface EventTableProps {
  events: AllEventTypes
}

interface Props<T extends BaseEvent> {
  event: T
  eventTypes: CommonEventType[]
  routePath: string
}

export const EventTableRow = <T extends BaseEvent>({
  event,
  eventTypes,
  routePath,
}: Props<T>) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const eventTypeName =
    eventTypes?.find((type) => type.id === event.type)?.name ?? ""
  const navigateToEvent = () => {
    navigate(`${routePath}/${event.id}`)
  }
  return (
    <tr className={styles.tableRow} onClick={navigateToEvent}>
      {eventTypeName && (
        <>
          <td className={styles.tableCell}>{t(eventTypeName)}</td>
          <td className={styles.dateCell}>
            {format(new Date(event.date), "dd.MM.yyyy")}
          </td>
        </>
      )}
    </tr>
  )
}

const TableHeader = () => {
  return (
    <thead className={styles.tableHeader}>
      <tr>
        <th className={styles.tableHeaderCell}>Tip</th>
        <th className={styles.tableHeaderCell}>Data</th>
      </tr>
    </thead>
  )
}

export const EventTable: React.FC<EventTableProps> = ({ events }) => {
  const isApiaryEvent = (event: {}) => {
    return event.hasOwnProperty("apiary")
  }

  const isHiveEvent = (event: {}) => {
    return event.hasOwnProperty("hive")
  }

  const isAnimalEvent = (event: {}) => {
    return event.hasOwnProperty("animal")
  }

  const isBirdsGroupEvent = (event: {}) => {
    return event.hasOwnProperty("birds_group")
  }

  return (
    <table className={styles.tableContainer}>
      <TableHeader />
      <tbody>
        {events.map((event, index) => (
          <>
            {isApiaryEvent(event) && (
              <ApiaryEventsTableRow event={event as ApiaryEvent} key={index} />
            )}
            {isHiveEvent(event) && (
              <HiveEventsTableRow event={event as HiveEvent} key={index} />
            )}
            {isAnimalEvent(event) && (
              <AnimalEventsTableRow
                event={event as GenericAnimalEvent}
                key={index}
              />
            )}
            {isBirdsGroupEvent(event) && (
              <BirdsGroupsEventsTableRow event={event as BirdsGroupsEvent} />
            )}
          </>
        ))}
      </tbody>
    </table>
  )
}
