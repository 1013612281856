import { BIRDS_GROUPS_CONSTS } from "@/features/events/birds-groups-events/types/birds-groups-events-consts.ts"

export const LOGIN_SCREEN_PATH = "/login"
export const LOST_PASSWORD_PATH = "/password"
export const HOME_SCREEN_PATH = "/home"
export const NOT_ENABLED = "/not-enabled"
export const FINANCIAL_SCREEN_PATH = "/finance"
export const FINANCE_TRANSACTIONS_SCREEN_PATH =
  FINANCIAL_SCREEN_PATH + "/transactions"
export const NEW_TRANSACTION_SCREEN_PATH =
  FINANCE_TRANSACTIONS_SCREEN_PATH + "/new"
export const FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH =
  FINANCE_TRANSACTIONS_SCREEN_PATH + "/history"
export const TASKS_SCREEN_PATH = "/tasks"
export const NEW_TASK_SCREEN_PATH = TASKS_SCREEN_PATH + "/new"
export const FARM_SCREEN_PATH = "/farm"
export const ACCOUNT_SCREEN_PATH = "/account"
export const NOTE_SCREEN_PATH = "/note"
export const NEW_NOTE_SCREEN_PATH = "/note/new"
export const NOTES_VIEW_PATH = "/notes"
export const ANIMAL_SCREEN_PATH = "/animal"
export const NEW_ANIMAL_SCREEN_PATH = "/animal/new"
export const ANIMAL_PROFILE_PATH = "/animal-profile-path"
export const NOTIFICATIONS_PATH = "/notifications"
export const MENU_PATH = "/menu"
export const SETTINGS_SCREEN_PATH = "/settings"
export const EDIT_FARM_PROFILE_PATH =
  SETTINGS_SCREEN_PATH + "/edit-farm-profile"
export const VIEW_COMPANY_INFO_PATH =
  SETTINGS_SCREEN_PATH + "/view-company-info"
export const EDIT_MY_PROFILE_PATH = SETTINGS_SCREEN_PATH + "/edit-my-profile"
export const RESET_PASSWORD_PATH = SETTINGS_SCREEN_PATH + "/reset-password"
export const NOTIFICATION_PREFERENCES_PATH =
  SETTINGS_SCREEN_PATH + "/notification-preferences"
export const TUTORIAL_PATH = SETTINGS_SCREEN_PATH + "/tutorial"
export const FAQ_PATH = SETTINGS_SCREEN_PATH + "/faq"
export const CONFIDENTIALITY_PATH = SETTINGS_SCREEN_PATH + "/confidentiality"
export const TERMS_PATH = SETTINGS_SCREEN_PATH + "/terms"
export const CONTACT_PATH = SETTINGS_SCREEN_PATH + "/contact"
export const ANIMAL_EVENT_SCREEN_PATH = "/animal-events"
export const NEW_ANIMAL_EVENT_SCREEN_PATH = ANIMAL_EVENT_SCREEN_PATH + "/new"
export const GESTATIONAL_CALCULATOR_SCREEN_PATH = "/gestational-calculator"
export const ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH =
  GESTATIONAL_CALCULATOR_SCREEN_PATH + "/all"
export const NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH =
  GESTATIONAL_CALCULATOR_SCREEN_PATH + "/new"

// Apiaries
export const APIARIES_SCREEN_PATH = "/apiaries"
export const APIARY_VIEW = `${APIARIES_SCREEN_PATH}/:apiaryId`
export const NEW_APIARY_SCREEN_PATH = APIARIES_SCREEN_PATH + "/new"
export const EDIT_APIARY_SCREEN_PATH = APIARY_VIEW + "/edit"

// Hives
export const ALL_HIVES_PATH = "/hives"
export const NEW_HIVE_FOR_APIARY_ROUTE =
  `${APIARIES_SCREEN_PATH}/:apiaryId${ALL_HIVES_PATH}` + "/new"
export const NEW_HIVE_ROUTE = ALL_HIVES_PATH + "/new"
export const HIVE_VIEW_ROUTE = `${APIARIES_SCREEN_PATH}/:apiaryId${ALL_HIVES_PATH}/:hiveId`
export const EDIT_HIVE_VIEW_ROUTE = HIVE_VIEW_ROUTE + "/edit"

// Queens
export const ALL_QUEENS_ROUTE = `/queens`
export const APIARY_HIVE_BASE_ROUTE = `${APIARIES_SCREEN_PATH}/:apiaryId${ALL_HIVES_PATH}/:hiveId/queen`
export const APIARY_HIVE_QUEEN_ROUTE = `${APIARY_HIVE_BASE_ROUTE}/:queenId`
export const NEW_QUEEN_FOR_HIVE_ROUTE = `${APIARY_HIVE_BASE_ROUTE}/new`
export const EDIT_QUEEN_VIEW_ROUTE = `${ALL_QUEENS_ROUTE}/:queenId/edit`
export const QUEEN_VIEW_ROUTE = `${ALL_QUEENS_ROUTE}/:queenId`
export const NEW_QUEEN_ROUTE = ALL_QUEENS_ROUTE + "/new"

// BeeKeepingEvents
export const BEE_KEEPING_EVENTS_ROUTE = "/bee-keeping-events"

// Apiary Events
export const APIARY_EVENTS_ROUTE = "/apiary-events"
export const NEW_APIARY_EVENT_ROUTE = APIARY_EVENTS_ROUTE + "/new"
export const APIARY_EVENT_VIEW_ROUTE = `${APIARY_EVENTS_ROUTE}/:apiaryEventId`

// Partners
export const PARTNERS_SCREEN_PATH = "/partners"
export const PARTNER_TRACTOARE_RO = PARTNERS_SCREEN_PATH + "/tractoare-ro/"

// Hive Events
export const HIVE_EVENTS_ROUTE = "/hive-events"
export const NEW_HIVE_EVENT_ROUTE = HIVE_EVENTS_ROUTE + "/new"
export const HIVE_EVENT_VIEW_ROUTE = `${HIVE_EVENTS_ROUTE}/:hiveEventId`

// AI ASSISTANT
export const AI_ASSISTANT_SCREEN_PATH = "/assistant"
export const AI_ASSISTANT_THREAD_VIEW = `${AI_ASSISTANT_SCREEN_PATH}/:threadId`
export const AI_ASSISTANT_THREADS_ALL = `${AI_ASSISTANT_SCREEN_PATH}/chats`

// Birds
export const BIRDS_GROUPS_SCREEN_PATH = "/birds-groups"
export const BIRDS_GROUP_VIEW = `${BIRDS_GROUPS_SCREEN_PATH}/:birdGroupId`
export const NEW_BIRDS_GROUP_SCREEN_PATH = BIRDS_GROUPS_SCREEN_PATH + "/new"
export const EDIT_BIRDS_GROUP_SCREEN_PATH = BIRDS_GROUP_VIEW + "/edit"

// Birds Events
export const BIRDS_GROUPS_EVENTS_SCREEN_PATH = "/birds-groups-events"
export const NEW_BIRDS_GROUPS_EVENTS_VIEW = `${BIRDS_GROUPS_EVENTS_SCREEN_PATH}/new`
export const BIRDS_GROUPS_EVENT_VIEW = `${BIRDS_GROUPS_EVENTS_SCREEN_PATH}/:${BIRDS_GROUPS_CONSTS.BIRDS_GROUP_EVENT_ID}`
