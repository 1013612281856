import { skipToken } from "@reduxjs/toolkit/query"
import isEqual from "lodash.isequal"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteBirdsGroupsEventMutation,
  useGetBirdsGroupsEventByIdQuery,
  useUpdateBirdsGroupsEventMutation,
} from "@/features/events/birds-groups-events/api/birds-groups-events-api.ts"
import { BirdsGroupEventEditor } from "@/features/events/birds-groups-events/components/birds-group-event-editor/birds-group-event-editor.tsx"
import { validateBirdsGroupEvent } from "@/features/events/birds-groups-events/types/birds-groups-events-schema.tsx"
import {
  type BirdsGroupEventInput,
  type BirdsGroupsEvent,
} from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"

const BirdsGroupsEventView = () => {
  const { t } = useTranslation()
  const { birdsGroupEventId } = useParams()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const { data, isLoading } = useGetBirdsGroupsEventByIdQuery(
    birdsGroupEventId || skipToken,
  )

  useEffect(() => {
    if (!isLoading && data) {
      setWorkingCopy(data)
    }
  }, [data, isLoading])

  const [workingCopy, setWorkingCopy] = useState<
    BirdsGroupEventInput | undefined
  >(data)
  const hasChanges = !isEqual(data, workingCopy)
  const [updateBirdsGroupsEventMutation] = useUpdateBirdsGroupsEventMutation()
  const [deleteBirdsGroupsEventMutation] = useDeleteBirdsGroupsEventMutation()

  const handleUpdate = async () => {
    if (!(await validateBirdsGroupEvent(workingCopy))) return
    await toast
      .promise(
        updateBirdsGroupsEventMutation({
          eventId: birdsGroupEventId || "",
          event: workingCopy as BirdsGroupsEvent,
        }).unwrap(),
        {
          pending: t("updatingEvent"),
          success: t("eventUpdated"),
          error: t("eventUpdateError"),
        },
      )
      .then(() => navigate(-1))
  }

  const deleteHiveEvent = async () => {
    if (data) {
      await toast
        .promise(deleteBirdsGroupsEventMutation(data).unwrap(), {
          pending: t("deletingEvent"),
          success: t("eventDeleted"),
          error: t("eventDeleteError"),
        })
        .then(() => {
          navigate(-1)
        })
    }
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteAnimalEventConfirmation"),
      content: t("eventWillBeDeleted"),
      onActionClick: deleteHiveEvent,
    })
  }

  return (
    <>
      <EditorTopBar
        text={t("birdsGroupEvent")}
        isModified={hasChanges}
        onSave={handleUpdate}
      />
      <PageContentWrapperLarge>
        {isLoading || !workingCopy ? (
          <LoadingAnimation />
        ) : (
          <>
            <BirdsGroupEventEditor
              birdsGroupsEvent={workingCopy}
              onBirdsGroupsEventChange={setWorkingCopy}
            />
            <div className={"mt-10"}>
              <DeleteButton
                text={t("deleteAnimalEvent")}
                onClick={handleDelete}
              />
            </div>
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default BirdsGroupsEventView
