import { useTranslation } from "react-i18next"

interface EventsInfoProps {
  eventsCount: number | undefined
  isLoading?: boolean
  isError?: boolean
}

export const EventsInfo = ({
  eventsCount,
  isError,
  isLoading,
}: EventsInfoProps) => {
  const { t } = useTranslation()
  const eventsString = eventsCount && eventsCount > 1 ? t("events") : t("event")

  if (eventsCount === 0) return

  return (
    <i className="flex w-full items-center justify-center text-neutral-500">
      {isLoading || isError ? t("loading") : `${eventsCount} ${eventsString}`}
    </i>
  )
}
