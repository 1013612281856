import React from "react"
import { useNavigate } from "react-router-dom"
import { AddEventIcon } from "@/assets/widgets/add-event-icon"
import { useGetAllBirdsGroupsEventsQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-api.ts"
import { BIRDS_GROUPS_EVENTS_FILTER_ENUM } from "@/features/events/birds-groups-events/hooks/use-birds-groups-events-filter.ts"
import { BIRDS_GROUPS_CONSTS } from "@/features/events/birds-groups-events/types/birds-groups-events-consts.ts"
import { EventTable } from "@/features/events/common/components/events-table/events-table.tsx"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import {
  BIRDS_GROUPS_EVENTS_SCREEN_PATH,
  NEW_BIRDS_GROUPS_EVENTS_VIEW,
} from "@/utils/constants/routes.ts"

interface Props {
  birdsGroupId: string
}

export const BirdsEventsWidget: React.FC<Props> = ({ birdsGroupId }) => {
  const navigate = useNavigate()
  const { data: events } = useGetAllBirdsGroupsEventsQuery({})
  const filteredEvents = events?.filter(
    (event) => event.birds_group === birdsGroupId,
  )

  const allEventsForHiveNavigationString =
    BIRDS_GROUPS_EVENTS_SCREEN_PATH +
    `?${BIRDS_GROUPS_EVENTS_FILTER_ENUM.BIRDS_GROUP}=${birdsGroupId}`
  const addEventNavigationString =
    NEW_BIRDS_GROUPS_EVENTS_VIEW +
    (birdsGroupId
      ? `?${BIRDS_GROUPS_CONSTS.BIRDS_GROUP_ID}=${birdsGroupId}`
      : "")
  const addItemGoTo = () => {
    navigate(addEventNavigationString)
  }

  return (
    <WidgetCard
      icon={<AddEventIcon />}
      title={"events"}
      navigateTo={allEventsForHiveNavigationString}
      {...(filteredEvents && filteredEvents.length > 0 && { addItemGoTo })}
    >
      {filteredEvents && filteredEvents.length > 0 && (
        <EventTable events={filteredEvents} />
      )}
      {!filteredEvents ||
        (filteredEvents.length === 0 && (
          <EmptyContentCreateItemButton navigateTo={addEventNavigationString} />
        ))}
    </WidgetCard>
  )
}
