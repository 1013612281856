import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"

const BIRDS_GROUPS_EVENT_TYPES_BASE_URL = "/birds-event-types/"

export const birdsGroupsEventTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBirdsGroupsEventTypes: builder.query<CommonEventType[], void>({
      query: () => ({
        url: BIRDS_GROUPS_EVENT_TYPES_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.EventTypes, id: "LIST" }],
    }),
  }),
})

export const { useGetAllBirdsGroupsEventTypesQuery } = birdsGroupsEventTypesApi
