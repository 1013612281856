import { toast } from "react-toastify"
import * as Yup from "yup"
import { type BirdsGroupInput } from "@/features/birds-groups/types/birds-groups.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

export const birdsGroupSchema = Yup.object().shape({
  name: Yup.string().required("nameRequired").max(100, "name100Characters"),
  description: Yup.string().max(500, "description500Characters"),
  count: Yup.number().required("countRequired").min(1, "countMin1"),
  species: Yup.string().required("speciesRequired"),
  location: Yup.string().required("locationRequired"),
  intended_use: Yup.string().required("intendedUseRequired"),
  breed: Yup.string().required("breedIsRequired"),
})

export const validateBirdsGroup = async (birdsGroup: BirdsGroupInput) => {
  try {
    await birdsGroupSchema.validate(birdsGroup, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
