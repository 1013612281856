import { useGetAllBirdsGroupsEventTypesQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-types-api.ts"

const useCheckBirdsGroupEventType = () => {
  const { data: eventTypes } = useGetAllBirdsGroupsEventTypesQuery()

  return (eventId: string | undefined, type: string) => {
    if (!eventId || !eventTypes) return false

    const eventType = eventTypes.find((event) => event.id == eventId)
    if (!eventType) return false

    const isType = eventType.name === type

    const isParentType =
      eventType.parent &&
      eventTypes.find((event) => event.id === eventType.parent)?.name === type

    return Boolean(isType || isParentType)
  }
}

export default useCheckBirdsGroupEventType
