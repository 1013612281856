import React from "react"
import { useTranslation } from "react-i18next"
import useGetBirdsGroupsLocation from "@/features/events/birds-groups-events/hooks/use-get-birds-groups-location.ts"
import { type BirdsGroupEventInput } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { EventLocationTupleDrawer } from "@/features/events/common/components/location-drawer/event-location-tuple-drawer.tsx"

interface Props {
  draftEvent: BirdsGroupEventInput
  onFromPositionChange: (fromPosition: string) => void
  onToPositionChange: (toPosition: string) => void
}

export const BirdsGroupEventMovementEditor: React.FC<Props> = ({
  draftEvent,
  onFromPositionChange,
  onToPositionChange,
}) => {
  const { t } = useTranslation()
  const allEventLocationOptions = useGetBirdsGroupsLocation()

  const handleFromPositionChange = (value: string) => {
    onFromPositionChange(value)
  }

  const handleToPositionChange = (value: string) => {
    onToPositionChange(value)
  }

  return (
    <>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("specificEventDetails")}
      </div>
      <EventLocationTupleDrawer
        firstPlaceholder={t("fromLocation")}
        secondPlaceholder={t("toLocation")}
        firstValue={draftEvent.event_data?.from_location}
        secondValue={draftEvent.event_data?.to_location}
        onChangeFirstValue={handleFromPositionChange}
        onChangeSecondValue={handleToPositionChange}
        options={allEventLocationOptions}
      />
    </>
  )
}
