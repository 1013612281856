import { skipToken } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import HiveIcon from "@/assets/icons/bees/hive-icon.tsx"
import genericQueenImg from "@/assets/images/generic-queen.webp"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import { useGetHiveByIdQuery } from "@/features/bees/api/hives-api.ts"
import { type Queen } from "@/features/bees/types/queens.ts"
import { BROWN } from "@/utils/constants/colors-constants.ts"
import { ALL_QUEENS_ROUTE } from "@/utils/constants/routes.ts"

interface Props {
  queen: Queen
}

export const QueenCard: React.FC<Props> = ({ queen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: hive,
    isLoading,
    isFetching,
  } = useGetHiveByIdQuery(queen.hive || skipToken)
  const queenViewNavigationString = queen && `${ALL_QUEENS_ROUTE}/${queen.id}/`

  if (isFetching || isLoading) {
    return (
      <Skeleton
        className={
          "widget-border h-[94px] w-full flex-shrink-0 rounded-[var(--fp-border-radius)]"
        }
      />
    )
  }

  return (
    <div
      className={
        "widget-border grid min-h-[94px] w-full grid-cols-[70px_auto] gap-2 rounded-[var(--fp-border-radius)] px-4 py-3"
      }
      onClick={() => navigate(queenViewNavigationString)}
    >
      <GenericPicture
        src={queen.picture || genericQueenImg}
        alt={t("queen")}
        size={70}
        borderRadius={12}
        style={{
          border: "1px solid",
          borderColor: BROWN,
        }}
      />
      <div className="grid w-full grid-cols-2 gap-2">
        <div className="flex flex-col justify-between">
          <div className="truncate text-base font-semibold text-neutral-600">
            {queen.name}
          </div>
          {hive ? (
            <div className="flex w-fit items-center gap-2 rounded-sm border border-amber-400 px-3 py-1.5 text-sm font-medium text-neutral-600">
              <HiveIcon className={"text-amber-500"} />
              <span className="truncate">{hive.code}</span>
            </div>
          ) : (
            <div className="flex w-fit items-center gap-2 rounded-sm bg-neutral-200 px-3 py-1.5 text-sm font-medium text-neutral-600">
              <HiveIcon />
              <span className="truncate">{t("noHive")}</span>
            </div>
          )}
        </div>

        <div className="flex flex-col items-end justify-between">
          {queen.year && (
            <div className="truncate rounded-sm border border-neutral-500 px-2 py-1 font-medium text-neutral-500">
              {queen.year}
            </div>
          )}
          <div className="max-w-full rounded-sm bg-red-50 px-3 py-1.5">
            <p className="truncate text-right font-medium text-neutral-600">
              {t(queen.origin)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
