import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type BirdsGroupsEventsFilterState } from "@/features/events/birds-groups-events/hooks/use-birds-groups-events-filter.ts"
import {
  type BirdsGroupEventInput,
  type BirdsGroupsEvent,
} from "@/features/events/birds-groups-events/types/birds-groups-events.ts"

export const BIRDS_GROUPS_EVENTS_API_BASE_URL = "/birds-events/"

export const birdsGroupsEventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBirdsGroupsEvents: builder.query<
      BirdsGroupsEvent[],
      Partial<BirdsGroupsEventsFilterState>
    >({
      query: (params) => ({
        url: BIRDS_GROUPS_EVENTS_API_BASE_URL,
        params: {
          birds_group: params.birds_group,
          date_from: params.date_from,
          date_to: params.date_to,
          event_type: params.event_type,
        },
      }),
      providesTags: [{ type: TagType.BirdsGroupsEvents, id: "LIST" }],
    }),
    getBirdsGroupsEventById: builder.query<BirdsGroupsEvent, string>({
      query: (id) => `${BIRDS_GROUPS_EVENTS_API_BASE_URL}${id}/`,
      providesTags: (_result, _error, id) => [
        { type: TagType.BirdsGroupsEvents, id },
      ],
    }),
    createBirdsGroupsEvent: builder.mutation<
      BirdsGroupsEvent,
      BirdsGroupEventInput
    >({
      query: (body) => ({
        url: BIRDS_GROUPS_EVENTS_API_BASE_URL,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (_queryCache, _error, input) => [
        { type: TagType.BirdsGroupsEvents, id: "LIST" },
        { type: TagType.BirdsGroups, id: input.birds_group },
      ],
    }),
    updateBirdsGroupsEvent: builder.mutation<
      BirdsGroupsEvent,
      { event: Partial<BirdsGroupEventInput>; eventId: string }
    >({
      query: ({ event, eventId }) => ({
        url: BIRDS_GROUPS_EVENTS_API_BASE_URL + `${eventId}/`,
        method: "PATCH",
        body: event,
      }),
      invalidatesTags: (_queryCache, _error, { event, eventId }) => [
        { type: TagType.BirdsGroupsEvents, id: "LIST" },
        { type: TagType.BirdsGroupsEvents, id: eventId },
        { type: TagType.BirdsGroups, id: event.birds_group },
      ],
    }),
    deleteBirdsGroupsEvent: builder.mutation<void, BirdsGroupsEvent>({
      query: (event) => ({
        url: BIRDS_GROUPS_EVENTS_API_BASE_URL + `${event.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_queryCache, _error, event) => [
        { type: TagType.BirdsGroupsEvents, id: "LIST" },
        { type: TagType.BirdsGroupsEvents, id: event.id },
        { type: TagType.BirdsGroups, id: event.birds_group },
      ],
    }),
  }),
})

export const {
  useGetAllBirdsGroupsEventsQuery,
  useGetBirdsGroupsEventByIdQuery,
  useCreateBirdsGroupsEventMutation,
  useUpdateBirdsGroupsEventMutation,
  useDeleteBirdsGroupsEventMutation,
} = birdsGroupsEventsApi
