export const GREY = "#959596"
export const DARK_GREY = "#414141"
export const LIGHT_GREY = "#959596"
export const BROWN = "#b5642d"
export const TRANSPARENT_BROWN = "rgba(181,100,45,0.45)"
export const YELLOW = "#f0a900"
export const WHITE = "#ffffff"
export const BLUE = "#447cc9"
export const COW_PURPLE = "#667BC6"
export const PASTEL_RED = "#E56057"
export const PASTEL_YELLOW = "#F0BF4B"
export const PASTEL_BLUE = "#89BAC7"
export const PASTEL_BROWN = "#CB9571"
export const NOTIFICATION_RED = "#FF1C1C"
export const NOTIFICATION_BLUE = "#447CC9"
export const NOTIFICATION_ORANGE = "#DD6701"
export const NOTIFICATION_BLACK = "#000000"
export const PALE_GREEN = "#B2BA48"
export const DONE_GREEN = "#ABB52D"
export const MENU_MY_FARM_COLOR = "#C69E85"
export const MENU_NOTES_COLOR = "#C2A0CE"
export const MENU_GESTATION_PERIOD_CALENDAR_COLOR = "#f3d276"
export const MENU_FINANCIAL_JOURNAL_COLOR = "#EC8585"
export const MENU_TASKS_COLOR = "#8DB8F5"
export const MENU_SETTINGS_COLOR = "#90aca5"
export const MENU_NOTIFICATIONS_COLOR = "#F8A06F"
export const MENU_VOICE_ASSISTANT_COLOR = "rgba(70,161,27,0.76)"
export const MENU_APIARY_EVENTS_COLOR = "rgba(75,168,234,0.7)"
export const CUSTOM_TOOLBAR_ICON_COLOR = "#ecb801"
export const MUSTARD_COLOR = "#ddbe40"
export const LIGHT_BLUE = "#c0e5f2"
export const CALVING_COLOR = MENU_NOTES_COLOR
