import { useGetAllBirdsGroupsEventTypesQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-types-api.ts"
import { type BirdsGroupsEvent } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"
import { EventTableRow } from "@/features/events/common/components/events-table/events-table.tsx"
import { BIRDS_GROUPS_EVENTS_SCREEN_PATH } from "@/utils/constants/routes.ts"

export const BirdsGroupsEventsTableRow = ({
  event,
}: {
  event: BirdsGroupsEvent
}) => {
  const { data: eventTypes } = useGetAllBirdsGroupsEventTypesQuery()

  return (
    <EventTableRow
      event={event}
      eventTypes={eventTypes ?? []}
      routePath={BIRDS_GROUPS_EVENTS_SCREEN_PATH}
    />
  )
}
