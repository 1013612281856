import { useGetAllBirdsGroupsEventsQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-api.ts"
import useGetEventsLocation from "@/features/events/common/hooks/use-get-events-location.ts"

const useGetBirdsGroupsLocation = () => {
  const { data } = useGetAllBirdsGroupsEventsQuery({})
  const { fromLocation, toLocation } = useGetEventsLocation({
    events: data,
  })

  const fromLocationOptionArray = Array.from(fromLocation).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  const toLocationOptionArray = Array.from(toLocation).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  return [...fromLocationOptionArray, ...toLocationOptionArray]
}

export default useGetBirdsGroupsLocation
