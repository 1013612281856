import React from "react"
import { GenericWidgetCard } from "@/components/generic-widget-card/generic-widget-card.tsx"
import { type HiveSummary } from "@/features/bees/types/hives.ts"
import { getImageForHive } from "@/features/bees/utils/apiaries-utils.ts"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  hive: HiveSummary
  apiaryId: string
}

export const ApiaryHiveCard: React.FC<Props> = ({ hive, apiaryId }) => {
  const navigationString = `${APIARIES_SCREEN_PATH}/${apiaryId}${ALL_HIVES_PATH}/${hive.id}/`
  const hiveImg = getImageForHive(hive)

  return (
    <GenericWidgetCard
      text={hive.code}
      navigationString={navigationString}
      image={hiveImg}
    />
  )
}
