import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { FilterScroll } from "@/components/filter-scroll/filter-scroll.tsx"
import SearchBar from "@/components/search-bar/search-bar.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { hiveTypeOptions } from "@/features/bees/types/hives.ts"
import {
  HIVES_FILTER_ENUM,
  type HiveFilterSelection,
} from "@/features/bees/utils/hive-filter.ts"

interface Props {
  selections: HiveFilterSelection
  handleApiaryChange: (apiary: string) => void
  handleTypeChange: (type: string) => void
  handleSearchQueryChange: (searchQuery: string) => void
}

export const HivesFilterTab: React.FC<Props> = ({
  selections,
  handleApiaryChange,
  handleTypeChange,
  handleSearchQueryChange,
}) => {
  const { t } = useTranslation()
  const { data: apiaries } = useGetApiariesQuery()
  const apiariesOptions = [
    {
      value: HIVES_FILTER_ENUM.ALL_APIARIES,
      label: HIVES_FILTER_ENUM.ALL_APIARIES,
    },
    ...(apiaries
      ? apiaries.map((apiary) => ({
          value: apiary.id,
          label: apiary.name,
        }))
      : []),
  ]

  const allHiveTypeOptions = [
    {
      value: HIVES_FILTER_ENUM.ALL_HIVE_TYPES,
      label: HIVES_FILTER_ENUM.ALL_HIVE_TYPES,
    },
    ...hiveTypeOptions,
  ]

  const memoHandleSearchQueryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleSearchQueryChange(event.target.value)
    },
    [handleSearchQueryChange],
  )

  const memoHandleApiaryChange = useCallback(
    (apiary: string) => {
      handleApiaryChange(apiary)
    },
    [handleApiaryChange],
  )

  const memoHandleTypeChange = useCallback(
    (type: string) => {
      handleTypeChange(type)
    },
    [handleTypeChange],
  )

  const selectedApiary = apiariesOptions.find(
    (apiary) => apiary.value === selections.apiary,
  )

  const selectedHiveType = allHiveTypeOptions.find(
    (hiveType) => hiveType.value === selections.type,
  )

  return (
    <div>
      <SearchBar
        value={selections.searchQuery}
        onChange={memoHandleSearchQueryChange}
        placeholder={t("searchHive")}
      />
      <FilterScroll>
        <SelectionRowDrawer
          multiple={false}
          title={t("selectApiary")}
          reorderOptions={false}
          renderTrigger={
            <DrawerTrigger>
              <SelectPill
                isActive={
                  selectedApiary?.label !== HIVES_FILTER_ENUM.ALL_APIARIES
                }
              >
                <div>
                  {selectedApiary
                    ? t(selectedApiary.label)
                    : t(apiariesOptions[0].label)}
                </div>
              </SelectPill>
            </DrawerTrigger>
          }
          options={apiariesOptions}
          selectedValues={selectedApiary?.value ?? ""}
          onSelectionChange={memoHandleApiaryChange}
        />
        <SelectionRowDrawer
          multiple={false}
          title={t("selectHiveType")}
          renderTrigger={
            <DrawerTrigger>
              <SelectPill
                isActive={
                  selectedHiveType?.value !== allHiveTypeOptions[0].value
                }
              >
                <div>
                  {selectedHiveType
                    ? t(selectedHiveType.label)
                    : t(allHiveTypeOptions[0].label)}
                </div>
              </SelectPill>
            </DrawerTrigger>
          }
          options={allHiveTypeOptions}
          selectedValues={selectedHiveType?.value ?? ""}
          onSelectionChange={memoHandleTypeChange}
        />
      </FilterScroll>
    </div>
  )
}
