import babyBird from "@/assets/birds-groups/baby-bird.png"
import duck from "@/assets/birds-groups/duck.png"
import goose from "@/assets/birds-groups/goose.png"
import juvenileBird from "@/assets/birds-groups/juvenile-bird.png"
import mixedAgeBirds from "@/assets/birds-groups/mixed-age-birds.png"
import adultBird from "@/assets/birds-groups/old-bird.png"
import ostrich from "@/assets/birds-groups/ostrich.png"
import turkey from "@/assets/birds-groups/turkey.png"
import {
  AGE_GROUPS,
  BIRDS_SPECIES_ENUM,
  type BIRDS_SPECIES_ENUM_TYPE,
} from "@/features/birds-groups/types/birds-groups.ts"

export const getBirdsGroupsDefaultImage = (ageGroup: AGE_GROUPS): string => {
  switch (ageGroup) {
    case AGE_GROUPS.ADULT:
      return adultBird
    case AGE_GROUPS.JUVENILE:
      return juvenileBird
    case AGE_GROUPS.HATCHLING:
      return babyBird
    case AGE_GROUPS.MIXTURE:
      return mixedAgeBirds
    default:
      return mixedAgeBirds
  }
}

export const getBirdsGroupsSpeciesDefaultImage = (
  species: BIRDS_SPECIES_ENUM_TYPE,
): string => {
  switch (species) {
    case BIRDS_SPECIES_ENUM.DUCK:
      return duck
    case BIRDS_SPECIES_ENUM.TURKEY:
      return turkey
    case BIRDS_SPECIES_ENUM.GOOSE:
      return goose
    case BIRDS_SPECIES_ENUM.OSTRICH:
      return ostrich
    default:
      return mixedAgeBirds
  }
}
