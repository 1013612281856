import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { CommonEventsTypeDropdown } from "@/features/events/common/components/common-events-drawer/common-events-type-dropdown.tsx"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"
import { cn } from "@/utils/styles.ts"

interface Props {
  selectedValue: string | undefined
  fallbackText: string
  onEventSelect: (eventType: string | undefined) => void
  eventTypesMap: Map<string, CommonEventType[]>
}

interface ReducedEventTypes {
  [key: string]: CommonEventType
}

/**
 * @param selectedValue id of the selected event type
 * @param fallbackText text to display when no event type is selected
 * @param onEventSelect callback to handle event type selection
 * @param eventTypesMap map of event types
 * @constructor
 */
export const CommonEventsDrawer: React.FC<Props> = ({
  selectedValue,
  fallbackText,
  onEventSelect,
  eventTypesMap,
}) => {
  const { t } = useTranslation()

  const getCurrentSelectedEventType = (currentValue: string) => {
    const eventTypesValues = [...eventTypesMap.values()]
    const flatEventTypesValues = eventTypesValues.flat(1)
    const reducedEventTypesValues =
      flatEventTypesValues.reduce<ReducedEventTypes>(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr,
        }),
        {},
      )

    return reducedEventTypesValues[currentValue]
  }

  const displayText = (currentValue: string | undefined) => {
    return currentValue
      ? t(getCurrentSelectedEventType(currentValue).name)
      : t(fallbackText)
  }

  const handleEventTypeSelect = (eventType: string | undefined) => {
    onEventSelect(eventType)
  }

  const [openArray, setOpenArray] = useState(
    Array.from({ length: eventTypesMap.size }, () => false),
  )

  const toggleDrawer = (indexToToggle: number) => {
    setOpenArray(
      openArray.map((isOpen, index) =>
        index === indexToToggle ? !isOpen : false,
      ),
    )
  }

  const handleFilterReset = () => {
    onEventSelect(undefined)
  }

  const clearButtonClassName = cn(
    "flex h-[50px] w-full items-center justify-center rounded-[8px] bg-neutral-100 p-4 text-[14px] font-semibold text-neutral-700",
    !selectedValue && "opacity-0",
  )

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <SelectPill isActive={!!selectedValue}>
          {displayText(selectedValue)}
        </SelectPill>
      </DrawerTrigger>
      <DrawerContent className={"h-3/4"}>
        <GenericTypeDrawerHeader title={t("selectEventType")} />
        <DrawerContentWrapper className={"flex flex-col justify-between"}>
          <div>
            {Array.from(eventTypesMap.entries()).map((entry, key) => (
              <CommonEventsTypeDropdown
                eventTypes={entry[1]}
                title={entry[0]}
                key={key}
                isOpen={openArray[key]}
                onOpen={() => toggleDrawer(key)}
                selectedEvent={selectedValue}
                onClick={(eventTypeId) => handleEventTypeSelect(eventTypeId)}
              />
            ))}
          </div>
          <DrawerClose className={"p-4"}>
            <button
              onClick={handleFilterReset}
              className={clearButtonClassName}
            >
              {t("resetFilter")}
            </button>
          </DrawerClose>
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
