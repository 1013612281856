import { format } from "date-fns"
import { type CommonBeesEventBase } from "@/features/events/common/types/common-bees-event-base.ts"

export interface BirdsGroupsEvent extends CommonBeesEventBase {
  birds_group: string
  birds_group_name: string
  type: string
  type_name: string
  event_data?: BirdsGroupEventData
}

export type BirdsGroupEventInput = Omit<
  BirdsGroupsEvent,
  "id" | "user" | "created_at" | "updated_at" | "birds_group_name" | "type_name"
>

export const initialBirdsGroupsEventState: BirdsGroupEventInput = {
  date: format(new Date(), "yyyy-MM-dd"),
  description: "",
  notes: "",
  type: "",
  birds_group: "",
  person_name: "",
}

export interface BirdsGroupEventData {
  event?: string
  medication?: string
  dosage?: string
  unit?: string
  administered_by?: string
  measurement_type?: string
  value?: string
  from_location?: string
  to_location?: string
}
