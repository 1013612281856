import { EventTableRow } from "@/features/events/common/components/events-table/events-table.tsx"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"
import { HIVE_EVENTS_ROUTE } from "@/utils/constants/routes.ts"

export const HiveEventsTableRow = ({ event }: { event: HiveEvent }) => {
  const { data: eventTypes } = useGetAllHiveEventTypesQuery()

  return (
    <EventTableRow
      event={event}
      eventTypes={eventTypes ?? []}
      routePath={HIVE_EVENTS_ROUTE}
    />
  )
}
