import isEqual from "lodash.isequal"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import {
  type BirdsGroupEventInput,
  initialBirdsGroupsEventState,
} from "../../types/birds-groups-events"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateBirdsGroupsEventMutation } from "@/features/events/birds-groups-events/api/birds-groups-events-api.ts"
import { BirdsGroupEventEditor } from "@/features/events/birds-groups-events/components/birds-group-event-editor/birds-group-event-editor.tsx"
import { BIRDS_GROUPS_CONSTS } from "@/features/events/birds-groups-events/types/birds-groups-events-consts.ts"
import { validateBirdsGroupEvent } from "@/features/events/birds-groups-events/types/birds-groups-events-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"

const getInitialBirdsGroupsEventState = (birdsGroupId: string | null) => {
  if (!birdsGroupId) return initialBirdsGroupsEventState
  return {
    ...initialBirdsGroupsEventState,
    birds_group: birdsGroupId,
  }
}

const NewBirdsGroupsEventsView = () => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const birdGroupId = searchParams.get(BIRDS_GROUPS_CONSTS.BIRDS_GROUP_ID)
  const [workingBirdsGroupsEvent, setWorkingBirdsGroupsEvent] =
    useState<BirdsGroupEventInput>(getInitialBirdsGroupsEventState(birdGroupId))
  const hasChanges = !isEqual(
    workingBirdsGroupsEvent,
    initialBirdsGroupsEventState,
  )

  const [createBirdsGroupsEvent] = useCreateBirdsGroupsEventMutation()

  const onBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          navigate(-1)
        },
      })
    } else {
      navigate(-1)
    }
  }

  const handleSave = async () => {
    const promise = createBirdsGroupsEvent(workingBirdsGroupsEvent).unwrap()
    if (!(await validateBirdsGroupEvent(workingBirdsGroupsEvent))) return
    await toast
      .promise(promise, {
        pending: t("creatingBirdsGroupEvent"),
        success: t("birdsGroupEventCreated"),
        error: t("errorCreatingBirdsGroupEvent"),
      })
      .then(() => {
        navigate(-1)
      })
      .catch((err) => {
        const errorDetail = err.data?.detail
        const errorMessage = errorDetail
          ? errorDetail.split(":").slice(1).join(":").trim()
          : "errorCreatingBirdsGroupEvent"

        toast.error(t(errorMessage))
      })
  }

  return (
    <>
      <EditorTopBar
        text={t("addEvent")}
        isModified={hasChanges}
        onBackClick={onBackClick}
        onSave={handleSave}
      />
      <PageContentWrapperLarge>
        <BirdsGroupEventEditor
          birdsGroupsEvent={workingBirdsGroupsEvent}
          onBirdsGroupsEventChange={setWorkingBirdsGroupsEvent}
        />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewBirdsGroupsEventsView
