import { useTranslation } from "react-i18next"
import BirdIcon from "@/assets/svg/bird-icon.svg?react"
import EggsIcon from "@/assets/svg/eggs-icon.svg?react"

export const BirdsCounterIconSet = () => {
  const { t } = useTranslation()

  return (
    <>
      <BirdIcon className="h-[30px] w-[30px] rounded bg-amber-500 p-1 text-white" />
      <p className={"text-neutral-600"}>{t("birdsCount")}</p>
    </>
  )
}

export const EggsCounterIconSet = () => {
  const { t } = useTranslation()

  return (
    <>
      <EggsIcon className="h-[30px] w-[30px] rounded bg-amber-700 p-1 text-white" />
      <p className={"text-neutral-600"}>{t("eggsCount")}</p>
    </>
  )
}
