import { format } from "date-fns"
import { useMemo } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import GenericItemsGroup from "@/components/grouping/generic-items-group.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { EventsInfo } from "@/features/events/common/components/events-info/events-info.tsx"
import { HiveEventCardWrapper } from "@/features/events/hive-events/components/hive-event-card/hive-event-card.tsx"
import { HiveEventsFilter } from "@/features/events/hive-events/components/hive-events-filter/hive-events-filter.tsx"
import { useFilterHiveEvents } from "@/features/events/hive-events/hooks/use-filter-hive-events.ts"
import { NEW_HIVE_EVENT_ROUTE } from "@/utils/constants/routes.ts"
import { groupItemsByTimeStampWithField } from "@/utils/helpers/time-grouping.ts"

export const AllHiveEventsView = () => {
  const { t } = useTranslation()
  const { filters, updateFilters, events, isLoading } = useFilterHiveEvents()

  const groupedEvents = useMemo(
    () =>
      events?.length ? groupItemsByTimeStampWithField(events, "date") : null,
    [events],
  )

  const timeFrame = {
    from: filters.dateFrom ? new Date(filters.dateFrom) : undefined,
    to: filters.dateTo ? new Date(filters.dateTo) : undefined,
  }

  const handleTimeFrameChange = (timeFrame: DateRange | undefined) => {
    if (!timeFrame) {
      updateFilters({ ...filters, dateFrom: undefined, dateTo: undefined })
      return
    }
    updateFilters({
      ...filters,
      dateFrom: timeFrame.from
        ? format(timeFrame.from, "yyyy-MM-dd")
        : undefined,
      dateTo: timeFrame.to ? format(timeFrame.to, "yyyy-MM-dd") : undefined,
    })
  }

  const handleSelectedApiaryChange = (apiary: string) => {
    updateFilters({ ...filters, apiary })
  }

  const handleSelectedEventTypeChange = (eventType: string) => {
    updateFilters({ ...filters, eventType })
  }

  const handleSelectedHiveChange = (hiveId: string) => {
    updateFilters({ ...filters, hiveId })
  }

  const renderContent = () => {
    if (isLoading) return <LoadingAnimation />

    return (
      <>
        <HiveEventsFilter
          selectedHive={filters.hiveId}
          selectedApiary={filters.apiaryId}
          onSelectedApiaryChange={handleSelectedApiaryChange}
          selectedEventType={filters.eventType}
          onSelectedEventTypeChange={handleSelectedEventTypeChange}
          selectedTimeFrame={timeFrame}
          onSelectedTimeFrameChange={handleTimeFrameChange}
          onSelectedHiveChange={handleSelectedHiveChange}
        />
        <EventsInfo isLoading={isLoading} eventsCount={events?.length} />
        {groupedEvents ? (
          <GenericItemsGroup
            items={groupedEvents}
            ItemComponent={HiveEventCardWrapper}
          />
        ) : (
          <NoResultsFound image="apiary" message="noEventsFound" />
        )}
      </>
    )
  }

  return (
    <>
      <MobileTopBar title={t("hiveEvents")} navigateTo={NEW_HIVE_EVENT_ROUTE} />
      <PageContentWrapperLarge>{renderContent()}</PageContentWrapperLarge>
    </>
  )
}
