import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"

const APIARY_EVENT_TYPES_BASE_URL = "/apiary-event-types/"

export const apiaryEventTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllApiaryEventTypes: builder.query<CommonEventType[], void>({
      query: () => ({
        url: APIARY_EVENT_TYPES_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    createApiaryEventType: builder.mutation<
      CommonEventType,
      Partial<CommonEventType>
    >({
      query: (body) => ({
        url: APIARY_EVENT_TYPES_BASE_URL,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    updateApiaryEventType: builder.mutation<
      CommonEventType,
      Partial<CommonEventType>
    >({
      query: (body) => ({
        url: `${APIARY_EVENT_TYPES_BASE_URL}${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    deleteApiaryEventType: builder.mutation<void, string>({
      query: (id) => ({
        url: `${APIARY_EVENT_TYPES_BASE_URL}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
  }),
})

export const {
  useGetAllApiaryEventTypesQuery,
  useCreateApiaryEventTypeMutation,
  useUpdateApiaryEventTypeMutation,
  useDeleteApiaryEventTypeMutation,
} = apiaryEventTypesApi
