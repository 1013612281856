import React from "react"
import { Link } from "react-router-dom"
import { cn } from "@/utils/styles.ts"

interface Props {
  navigationString: string
  image: string
  text: string
  secondText?: string
}

export const GenericWidgetCard: React.FC<Props> = ({
  navigationString,
  image,
  text,
  secondText,
}) => {
  return (
    <Link to={navigationString}>
      <div
        className={cn(
          "fp-border flex h-[135px] w-[104px] flex-col items-center justify-between rounded-[var(--fp-border-radius)] p-[6px]",
          secondText && "h-[145px]",
        )}
      >
        <img
          className={
            "bg-hive fp-border h-[90px] w-[90px] overflow-hidden rounded-[8px] object-cover"
          }
          src={image}
          alt={text}
        />
        <div className="flex w-full flex-col items-center">
          <p className="w-full truncate text-center font-semibold text-neutral-700">
            {text}
          </p>
          {secondText && (
            <div className="text-xs font-semibold">
              <span className="text-brown-500">{secondText}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}
