import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import { EventTableRow } from "@/features/events/common/components/events-table/events-table.tsx"
import { APIARY_EVENTS_ROUTE } from "@/utils/constants/routes.ts"

export const ApiaryEventsTableRow = ({ event }: { event: ApiaryEvent }) => {
  const { data: eventTypes } = useGetAllApiaryEventTypesQuery()

  return (
    <EventTableRow
      event={event}
      eventTypes={eventTypes ?? []}
      routePath={APIARY_EVENTS_ROUTE}
    />
  )
}
