export const EN_TRANSLATIONS: Record<string, any> = {
  translation: {
    hello: "Hello!",
    featureNotYetImplemented: "This feature is not yet implemented.",
    yes: "Yes",
    no: "No",
    yesterday: "Yesterday",
    today: "Today",
    tomorrow: "Tomorrow",
    thisWeek: "This Week",
    thisMonth: "This Month",
    lastMonth: "Last Month",
    thisYear: "This Year",
    lastYear: "Last Year",
    pastYears: "Past Years",
    homeTab: "Home",
    tasksTab: "Tasks",
    addBottomMenu: "Add",
    farmTab: "My Farm",
    notifications: "Notifications",
    notification: "Notification",
    menu: "Menu",
    accountTab: "My Account",
    oneSecond: "One moment...",
    weather: "Weather",
    notes: "Notes",
    addNote: "Add a Note",
    newNote: "New Note",
    myNote: "My Note",
    placeholderTitle: "Title",
    placeholderText: "Your text",
    deleteNote: "Delete Note",
    searchNote: "Search Note",
    noNotesFound: "No Notes Found",
    shareNote: "Share Note",
    formatNote: "Format",
    notesBackConfirmation:
      "You have made changes. Are you sure you want to leave without saving?",
    sureWantToLeave: "Are you sure you want to leave without saving?",
    youMadeChanges: "You have made changes.",
    unsavedChanges: "Unsaved Changes!",
    continueWhereYouLeftOff: "Continue where you left off",
    youHaveUnsavedChanges:
      "You have unsaved changes from your previous session. Do you want to continue with these changes or discard them?",
    youWillLoseChanges:
      "You will lose unsaved changes. Do you want to continue?",
    backConfirmation: "Are you sure you want to go back to Notes?",
    noteWillBeDeleted: "The current note will be deleted if yes.",
    continue: "Continue",
    leave: "Leave",
    discard: "Discard",
    delete: "Delete",
    stay: "Stay",
    save: "Save",
    goToNotes: "Go to Notes",
    seeAllNotes: "See all Notes",
    deleteNoteConfirmation: "Are you sure you want to delete the Note?",
    deletingNote: "Deleting note...",
    noteDeleted: "Note has been deleted!",
    errorDeletingNote: "An error occurred while deleting the note!",
    updatingNote: "Updating note...",
    noteUpdated: "Note has been updated!",
    errorUpdatingNote: "An error occurred while updating the note!",
    postingNote: "Creating note...",
    notePosted: "Note has been created!",
    notePostError: "An error occurred while creating the note!",
    noNoteAssociated: "No associated note",
    oneNoteAssociated: "One associated note",
    notesAssociated: "associated notes",
    noteContentIsMandatory: "Note content is mandatory!",
    seeOtherNotesAssociated: "See other associated notes",
    hideOtherNotesAssociated: "Hide other associated notes",
    draft: "draft",
    unsavedChangesIndicator: "unsaved changes",
    notEnoughData: "Not enough data to display the chart",
    notesWillBeSetAsDraft:
      "Notes will be set as draft. You can return later to save the changes.",
    noteWasLoadedWithPreviousData:
      "Note was loaded with previous data. Do you want to keep the data and continue?",

    //Tasks
    taskWasLoadedWithPreviousData:
      "Task was loaded with previous data. Do you want to keep the data and continue?",
    tasks: "Tasks",
    dueTasks: "Overdue Tasks",
    currentTasks: "Current Tasks",
    futureTasks: "Future Tasks",
    searchTask: "Search Task",
    noTasksFound: "No Tasks Found",
    newTask: "New Task",
    myTask: "My Task",
    title: "Title",
    description: "Description",
    deleteTask: "Delete Task",
    disinfestation: "Pest Control",
    treatments: "Treatments",
    status: "Status",
    category: "Category",
    chooseCategory: "Choose Category",
    ALL: "All Tasks",
    DONE: "Completed Tasks",
    TODO: "Current Tasks",
    backConfirmationTasks: "Are you sure you want to go back to Tasks?",
    backConfirmationSettings: "Are you sure you want to go back to Settings?",
    taskWillBeDeleted: "The current task will be deleted.",
    modificationsWillBeLost: "Changes will be lost.",
    onTime: "On time",
    before30: "30 min before",
    beforeHour: "1 hour before",
    beforeDay: "1 day before",
    beforeWeek: "1 week before",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    Date: "Date",
    Hour: "Hour",
    Reminder: "Reminder",
    Recurrence: "Recurrence",
    AssociateNote: "Associate Note",
    AssociateAnimal: "Associate Animal",
    AssociateTask: "Associate Task",
    dashboardCurrentTasks: "Current",
    dashboardFutureTasks: "Future",
    dashboardFinalizedTasks: "Completed",
    dashboardOverdueTasks: "Overdue",
    seeAllTasks: "See all tasks",
    deleteTaskConfirmation: "Are you sure you want to delete the Task?",
    allCategories: "All categories",
    noNewTasks: "You have no new tasks",
    updatingTask: "Updating task...",
    taskUpdated: "Task has been updated!",
    errorUpdatingTask: "An error occurred while updating the task!",
    creatingTask: "Creating task...",
    taskCreated: "Task has been created!",
    errorCreatingTask: "An error occurred while creating the task!",
    deletingTask: "Deleting task...",
    taskDeleted: "Task has been deleted!",
    errorDeletingTask: "An error occurred while deleting the task!",
    oneTaskAssociated: "One associated task",
    tasksAssociated: "associated tasks",
    taskNotFound: "Task not found!",
    titleRequired: "Title is required to create a task",
    title5Characters: "Title must have at least 5 characters",
    title100Characters: "Title must have at most 100 characters",
    addCategory: "Add Category",
    editCategory: "Edit Category",
    selectTaskCategory: "Select Task Category",
    taskWillBeSetAsDraft:
      "Task will be set as draft. You can return later to save the changes.",
    taskSetAsDraft: "Task has been set as draft.",
    addTask: "Add Task",
    animalWasLoadedWithPreviousData:
      "Animal was loaded with previous data. Do you want to keep the data and continue?",
    animalWillBeSetAsDraft:
      "Animal will be set as draft. You can return later to save the changes.",
    ain: "Ear Tag No.",
    myFarm: "My Farm",
    searchAnimal: "Search animal: Name, ear tag no., status",
    outOfExploitation: "Out of farm",
    removeAnimalFromExploitation: "Mark exit from farm",
    searchBreed: "Search Breed",
    newAnimal: "Add Animal",
    addCow: "Add Cow",
    myAnimal: "My Animal",
    addGoat: "Add Goat",
    addSheep: "Add Sheep",
    addPork: "Add Pig",
    addBird: "Add Bird",
    addMammal: "Add Mammal",
    addBee: "Add Hive",
    statusCows: "Cattle Status",
    statusGoats: "Goats Status",
    statusSheep: "Sheep Status",
    statusPork: "Pigs Status",
    statusBirds: "Birds Status",
    statusBee: "Beehives Status",
    statusAnimal: "Animals Status",
    backConfirmationAnimals: "Are you sure you want to go back to My Farm?",
    animalWillBeDeleted: "The current animal will be deleted if yes.",
    name: "Name",
    surname: "Surname",
    taskTitleError: "Title is required to create a task",
    crotal: "Ear Tag",
    Male: "Male",
    Female: "Female",
    allGenders: "Both Genders",
    sex: "Sex",
    registryEntryWay: "Registry Entry Method",
    Birth: "Birth",
    Beef: "Beef",
    Meat: "Meat",
    Purchase: "Purchase",
    Production: "Production",
    Dairy: "Dairy",
    Sale: "Sale",
    Sacrifice: "Sacrifice",
    Transfer: "Transfer",
    Loss: "Loss",
    Death: "Death",
    details: "Details",
    categories: "Categories",
    deleteAnimal: "Delete animal",
    birthDate: "Birth date",
    entryDate: "Entry date",
    ALL_ANIMALS: "All categories",
    ALL_GENDERS: "All genders",
    Cows: "Cattle",
    Cow: "Cow",
    singleSheep: "Sheep",
    Sheep: "Sheep",
    FEMALE_SHEEP: "Ewe",
    YOUNG_GOAT: "Kid",
    MALE_GOAT: "Buck",
    FEMALE_GOAT: "Doe",
    RAM: "Ram",
    singlePork: "Pig",
    Pork: "Pigs",
    MALE_PIG: "Boar",
    FEMALE_PIG: "Sow",
    PIGLET: "Piglet",
    singleGoat: "Goat",
    Goat: "Goats",
    breed: "Breed",
    Lamb: "Lamb",
    Chicken: "Chicken",
    Hen: "Hen",
    Cock: "Rooster",
    Bee: "Bee",
    exploitEntryDate: "Farm entry date",
    calves_count: "Births",
    gestation_count: "Gestation confirmations",
    medical_treatments_count: "Treatments",
    insemination_count: "Mating / Inseminations",
    lactation_count: "Lactations",
    nameAnimalError: "* Animal name is required",
    categoryAnimalError: "* Animal category is required",
    breedAnimalError: "* Animal breed is required",
    crotalAnimalError: "* Animal ear tag number is required",
    sexAnimalError: "* Animal sex is required",
    birthDateAnimalError: "* Animal birth date is required",
    entryDateAnimalError: "* Farm entry date is required",
    registryEntryWayAnimalError: "* Registry entry method is required",
    productionAnimalError: "* Production type is required",
    detailsAnimalError: "* Animal details are required",
    exploitEntryDateAnimalError: "* Farm entry date is required",
    fieldError: "* This field is required",
    age: "Age",
    years: "years",
    year: "year",
    year_uppercase: "Year",
    months: "months",
    month: "month",
    and: "and",
    lessThanAMonth: "less than a month",
    allAges: "All ages",
    age02cow: "Calf",
    age23cow: "Heifer",
    age3plusCow: "Mature",
    age510: "5 - 10 years",
    species: "Species",
    allSpecies: "All Species",
    Calf: "Calf",
    Veal: "Heifer",
    mature: "Mature",
    goats: "Goats",
    ALL_SPECIES: "All Species",
    heads: "Heads",
    addAnimal: "Add animal",
    animalSummary: "Animal Data Summary",
    animalMother: "Animal's Mother",
    animalFather: "Animal's Father",
    seeFarm: "See my farm",
    deleteAnimalConfirmation: "Are you sure you want to delete the animal?",
    deletingAnimal: "Deleting animal...",
    animalDeleted: "Animal has been deleted!",
    errorDeletingAnimal: "An error occurred while deleting the animal!",
    updatingAnimal: "Updating animal...",
    animalUpdated: "Animal has been updated!",
    animalUpdateError: "An error occurred while updating the animal!",
    creatingAnimal: "Creating animal...",
    animalCreated: "Animal has been created!",
    animalCreateError: "An error occurred while creating the animal!",
    allAnimals: "All animals",
    animals: "Animals",
    noAnimalAssociated: "No associated animal",
    oneAnimalAssociated: "One associated animal",
    oneAnimalSelected: "One animal selected",
    animalsAssociated: "associated animals",
    seeOtherAnimalsAssociated: "See other associated animals",
    hideOtherAnimalsAssociated: "Hide other associated animals",
    animalsSelected: "animals selected",
    ainRequired: "Ear Tag No. is required to create an animal",
    genderRequired: "Gender is required to create an animal",
    birthDateRequired: "Birth date is required to create an animal",
    entryDateRequired: "Entry date is required to create an animal",
    entryWayIsRequired: "Registry entry method is required",
    intendedPurposeIsRequired: "Production is required",
    speciesRequired: "Species is required to create an animal",
    breedIsRequired: "Breed is required to create an animal",
    entryDateMustBeLaterThanBirthDate:
      "Entry date must be later than birth date",
    birthDateCannotBeInTheFuture: "Birth date cannot be in the future",
    entryDateCannotBeInTheFuture: "Entry date cannot be in the future",
    nameFarmRequired: "Farm name is required!",
    nameMaxLength: "Name must have at most 100 characters!",
    selectAnimalMother: "Select animal's mother",
    selectAnimalFather: "Select animal's father",
    ALL_STATUSES: "All Statuses",
    HEAT: "Heat",
    GESTATING: "Gestating",
    LACTATING: "Lactating",
    ILL: "To investigate",
    HEALTHY: "Healthy",
    DRY: "Dry",
    gestationPeriodCalendar: "Gestation Calendar",
    jurnal: "Journal",
    vocalAssistant: "Voice Assistant",
    contactTheSpecialist: "Contact the Specialist",
    contactSpecialist: "Contact the Specialist",
    voiceAssistant: "Voice Assistant",
    goodMorning: "Good morning",
    goodAfternoon: "Good afternoon",
    goodEvening: "Good evening",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    dashboard: "Dashboard",
    allNotifications: "All notifications",
    ALL_NOTIFICATIONS: "All notifications",
    ALERTS: "Alerts",
    REMINDERS: "Reminders",
    INFO: "Information",
    MANDATORY: "Mandatory",
    allRead: "All",
    read: "Read",
    unread: "Unread",
    all: "All",
    seeAllNotifications: "See all notifications",
    noNewNotifications: "You have no new notifications",
    notificationWasRead: "Notification has been read!",
    notificationsWereRead: "Notifications have been read!",
    updateNotificationError:
      "An error occurred while updating the notification!",
    updateNotificationsError: "An error occurred while updating notifications!",
    notificationError: "A notification error occurred!",
    updatingNotification: "Updating notification...",
    updatingNotifications: "Updating notifications...",
    followLink: "Follow link",
    youSureYouWantToReadThemAll:
      "Are you sure you want to mark all notifications as read?",
    markAllAsRead: "Mark all as read",
    cancel: "Cancel",
    settings: "Settings",
    farmProfile: "Farm Profile",
    hiveProfile: "Apiary Profile",
    companyInfo: "Company Information",
    farmName: "Farm Name",
    farmNameRequired: "Farm Name is required!",
    address: "Address",
    location: "Location",
    selectLocation: "Location (e.g., Building A, Zone 4, Paddock 2)",
    setFarmImage: "Set a new farm photo",
    takePhoto: "Take a photo",
    choosePhoto: "Choose a photo",
    changePhoto: "Change photo",
    deletePhoto: "Delete photo",
    farmInfo: "Company Information",
    addFarmName: "Company Name",
    addCui: "Tax ID",
    accountSettings: "Account Settings",
    myProfile: "My Profile",
    resetPassword: "Reset Password",
    notificationPreferences: "Notification Preferences",
    email: "Email Address",
    pushNotifications: "Push Notifications",
    editPhone: "Edit Phone",
    setUserImage: "Set a new profile photo",
    inputCurrentPassword: "Enter Current Password",
    inputNewPassword: "Enter New Password",
    confirmNewPassword: "Confirm New Password",
    information: "Information",
    tutorials: "Tutorials",
    faq: "Frequently Asked Questions",
    confidentiality: "Privacy Policy",
    termsAndServices: "Terms and Services",
    appVersion: "App Version",
    whatIsFarmApp: "What is FARM APP?",
    lastUpdate: "Last Update",
    yourOpinion: "Your Opinion",
    recommend: "Recommend",
    likeUs: "Like us on Social Media",
    contactUs: "Contact Us",
    followUs: "Follow Us",
    recommendUsing: "Recommend the app using:",
    subject: "Subject",
    message: "Message",
    signOut: "Sign out",
    signOutConfirmation: "Are you sure you want to exit the application?",
    deletePhotoConfirmation: "Are you sure you want to delete the photo?",
    linkWillBeHere: "Link to our app will be here",
    farmProfileUpdated: "Farm profile has been updated!",
    userProfileUpdated: "Profile has been updated!",
    errorOccurred: "An error occurred!",
    processing: "Processing...",
    contact: "Contact",
    lostPassword: "Forgot password",
    password: "Password",
    lostYourPassword: "Lost your password?",
    dontWorryHappensToAllOfUs: "Don't worry, it happens to all of us!",
    enterEmailResetPassword:
      "Enter your email address below, and we'll send you a password reset link.",
    wrongLoginCredentials: "Wrong email or password!",
    login: "Login",
    logingIn: "Logging in...",
    back: "Back",
    welcome: "Welcome!",
    nameUserRequired: "Name is required!",
    lastNameRequired: "Surname is required!",
    lastNameMaxLength: "Surname must have at most 100 characters",
    emailRequired: "Email address is required!",
    phoneNumberRequired: "Phone number is required!",
    send: "Send",
    urlCopiedSuccess: "URL has been copied successfully!",

    // Gestation Period Calendar
    stepByStepGestationEvolution: "Step by step pregnancy evolution",
    computeProbableBirthDate: "Calculate probable birth date",
    testNow: "Test now",
    confirm: "Confirm",
    underDevelopment: "This feature is still under development",
    notAvailableInPackage: "This feature is not available",
    gestationalCalculator: "Gestational Calculator",
    createNewCalendar: "Create a new calendar",
    generateCalendar: "Generate a calendar",
    saveCalendar: "Save calendar",
    calendarSavedSuccessfully: "Calendar has been saved successfully!",
    animalMustBeFemale:
      "Animal must be female to create a gestational calendar",
    animalMustBeCow: "Gestational calendar is only available for cows",
    loadingCalendarPreview: "Loading calendar preview...",
    previewLoadedSuccessfully: "Preview has been loaded successfully!",
    errorLoadingPreview: "An error occurred while loading the preview!",
    errorFetchingAnimal: "An error occurred while fetching the animal!",
    searchCalendar: "Search calendar",
    selectAnimal: "Select Animal",
    gestationalCalendar: "Gestational Calendar",
    deleteCalendar: "Delete Calendar",
    deletingCalendar: "Deleting calendar...",
    calendarDeletedSuccessfully: "Calendar has been deleted successfully!",
    calendarDeleteError: "An error occurred while deleting the calendar!",
    calendarWillBeDeleted: "The current calendar will be deleted.",
    deleteCalendarConfirmation: "Are you sure you want to delete the calendar?",
    deleteAssocAnimalEvents:
      "Do you want to delete the events associated with the calendar?",
    toCalving: "until calving",
    untilCalvingIntervalEnd: "until interval end",
    days: "days",
    toNextTask: "until next task",
    hours: "hours",
    minutes: "minutes",
    nextActivity: "Next Activity",
    gestationalCalendarText:
      "Calculate calving date and generate useful tasks for the gestation period.",
    calendarPreviewLoadedSuccessfully:
      "Calendar preview has been loaded successfully!",

    // Finance Widget
    financialJournal: "Financial Journal",
    searchTransaction: "Search Transaction",
    addTransaction: "Add Transaction",
    transaction: "Transaction",
    transactions: "Transactions",
    EXPENSES: "Expenses",
    Outflow: "Expenses",
    INCOMES: "Income",
    Inflow: "Income",
    selectTransactionCategory: "Select transaction category",
    EU_FOUNDS: "EU Funds",
    PROFIT: "Profit",
    SELLING_ANIMALS: "Animal Sales",
    DONATIONS: "Donations",
    SELLING_GOODS: "Product Sales",
    EQUIPMENT_ACQUISITION: "Equipment Purchase",
    MAINTENANCE: "Maintenance",
    ANIMALS_FOOD: "Animal Feed",
    TREATMENTS: "Treatments",
    TRANSPORTATION: "Transportation",
    VETERINARY: "Veterinary Services",
    SALARIES: "Salaries",
    SOFTWARE_ACQUISITION: "Software Purchase",
    ADD_CATEGORY: "Add Category",
    categoryName: "Category Name",
    loadingCategories: "Loading categories...",
    categorySaved: "Category has been saved!",
    errorCreatingCategory: "An error occurred while creating the category!",
    savingCategory: "Saving category...",
    updatingCategory: "Updating category...",
    categoryUpdated: "Category has been updated!",
    errorUpdatingCategory: "An error occurred while updating the category!",
    deleteCategory: "Delete Category",
    deletingCategory: "Deleting category...",
    categoryDeleted: "Category has been deleted!",
    errorDeletingCategory: "An error occurred while deleting the category!",
    transactionHistory: "Transaction History",
    savingTransaction: "Saving transaction...",
    transactionSaved: "Transaction has been saved!",
    transactionSaveError: "An error occurred while saving the transaction!",
    deleteTransactionConfirmation:
      "Are you sure you want to delete the transaction?",
    deleteTransactionCategoryConfirmation:
      "Are you sure you want to delete the category?",
    transactionCategoryWillBeDeleted:
      "The transaction category will be deleted.",
    transactionWillBeDeleted: "The current transaction will be deleted.",
    deleteTransaction: "Delete Transaction",
    transactionDeleted: "Transaction has been deleted!",
    deletingTransaction: "Deleting transaction...",
    startDate: "Start date",
    viewStats: "View statistics",
    endDate: "End date",
    custom: "Custom period",
    all_time: "All time",
    current_week: "Current week",
    current_month: "Current month",
    last_week: "Last week",
    last_month: "Last month",
    last_year: "Last year",
    selectTimePeriod: "Select time period",
    noTransactionsFound: "Oops, no transactions found...",
    noAnimalsFound: "Oops, no animals found...",
    lastTransactions: "Last Transactions",

    amountRequired: "Amount is required to create a transaction",
    dateRequired: "Date is required to create a transaction",
    categoryRequired: "Category is required to create a transaction",
    balance: "Balance",
    financialStats: "Financial Statistics",
    weAreSorry: "We are sorry...",
    anErrorOccurred: "An error occurred.",
    reloadApp: "Reload application",
    events: "Events",
    addEvent: "Add Event",
    addAnimalExit: "Animal Exit",
    confirmCalving: "Confirm Calving",
    calvingConfirmed: "Calving has been confirmed",
    selectEventType: "Select Event Type",
    selectMedication: "Select Medication",
    Treatment: "Treatment",
    Measurement: "Measurement",
    Movement: "Movement",
    Insemination: "Insemination",
    NaturalMating: "Natural Mating",
    PregnancyCheckPositive: "Positive Pregnancy Check",
    PregnancyCheckNegative: "Negative Pregnancy Check",
    HealthCheckPositive: "Positive Health Check",
    HealthCheckNegative: "Negative Health Check",
    HoofHealthCheck: "Hoof Health Check",
    HeatDetection: "Heat Detection",
    Abortion: "Abortion",
    DryOff: "Dry Off",
    Weaning: "Weaning",
    LactationStart: "Lactation Start",
    LactationEnd: "Lactation End",
    DietChange: "Diet Change",
    Shearing: "Shearing",
    HoofTrimming: "Hoof Trimming",
    EarTagging: "Ear Tagging",
    AnimalTransferExitEventType: "Exit Events",
    AnimalSacrifice: "Sacrifice",
    AnimalSale: "Animal Sale",
    AnimalDeath: "Animal Death",
    AnimalLoss: "Animal Loss",
    AnimalTransferExit: "Transfer Exit",
    Castration: "Castration",
    Dehorning: "Dehorning",
    VeterinaryVisit: "Veterinary Visit",
    BehaviorObservation: "Behavior Observation",
    EntryIntoQuarantine: "Entry into Quarantine",
    ExitFromQuarantine: "Exit from Quarantine",
    Isolation: "Isolation",
    Vaccination: "Vaccination",
    IllnessTreatment: "Illness Treatment",
    Deworming: "Deworming",
    InjuryTreatment: "Injury Treatment",
    Surgery: "Surgery",
    PostSurgeryFollowUp: "Post-Surgery Follow-Up",
    SupplementAdministration: "Supplement Administration",
    WeightCheck: "Weight Check",
    BodyConditionScoring: "Body Condition Scoring",
    Other: "Other",
    addEventType: "Add a new event type",
    addMedicationType: "Add a new treatment",
    eventName: "Event Name",
    medicationName: "Treatment Name",
    deleteEventType: "Delete event type",
    fromLocation: "From Location",
    fromPosition: "From Position",
    toPosition: "To Position",
    toLocation: "To Location",
    departureDate: "Departure Date",
    arrivalDate: "Arrival Date",
    HeightCheck: "Height Check",
    weight: "Weight",
    otherObservations: "Other Observations",
    generalEventDetails: "General Event Details",
    specificEventDetails: "Specific Event Details",
    transactionAmount: "Transaction Amount",
    value: "Value",
    savingEvent: "Saving event...",
    eventSaved: "Event has been saved!",
    viewEvent: "View event",
    errorSavingEvent: "An error occurred while saving the event!",
    dosage: "Dosage",
    deleteAnimalEvent: "Delete Event",
    animalEvent: "Animal Event",
    deletingAnimalEvent: "Deleting event...",
    animalEventDeleted: "Event has been deleted!",
    animalEventDeleteError: "An error occurred while deleting the event!",
    deleteAnimalEventConfirmation: "Are you sure you want to delete the Event?",
    eventWillBeDeleted: "The current event will be deleted if yes.",
    updatingAnimalEvent: "Updating event...",
    animalEventUpdated: "Event has been updated!",
    animalEventUpdateError: "An error occurred while updating the event!",
    typeRequired: "Type is required to create an event",
    dateRequiredEvent: "Date is required to create an event",
    animalRequired: "Animal is required to create an event",
    fromLocationRequired: "Departure location is required to create this event",
    toLocationRequired: "Arrival location is required to create this event",
    valueRequired: "Value is required to create this event",
    dosageRequired: "Dosage is required to create this event",
    medicationRequired: "Medication is required to create this event",
    quantityRequired: "Quantity is required to create this event",
    positionRequired: "Position is required to create this event",
    toApiaryRequired: "Destination apiary is required to create this event",
    TreatmentEventType: "Treatment Events",
    MeasurementEventType: "Measurement Events",
    MovementEventType: "Movement Events",
    ReproductiveEventType: "Reproductive Events",
    OtherEventType: "Other Events",
    searchAnimalEvent: "Search Animal Event",
    animalEvents: "Animal Events",
    savingEventType: "Saving event type...",
    savingMedicationType: "Saving treatment type...",
    eventTypeSaved: "Event type has been saved!",
    medicationTypeSaved: "Treatment type has been saved!",
    errorCreatingEventType: "An error occurred while creating the event type",
    errorCreatingMedicationType:
      "An error occurred while creating the treatment type",
    updatingEventType: "Updating event type",
    updatingMedicationType: "Updating treatment type",
    eventTypeUpdated: "Successfully updated!",
    medicationTypeUpdated: "Successfully updated!",
    errorUpdatingEventType: "An error occurred while updating the event type!",
    errorUpdatingMedicationType:
      "An error occurred while updating the treatment type!",
    deleteEventTypeConfirmation:
      "Are you sure you want to delete the event type?",
    deleteMedicationTypeConfirmation:
      "Are you sure you want to delete the treatment type?",
    eventTypeWillBeDeleted: "The event type will be deleted.",
    medicationTypeWillBeDeleted: "The treatment type will be deleted.",
    deletingEventType: "Deleting event type...",
    deletingMedicationType: "Deleting treatment type...",
    eventTypeDeleted: "Event type has been deleted!",
    medicationTypeDeleted: "Treatment type has been deleted!",
    errorDeletingEventType: "An error occurred while deleting the event type",
    errorDeletingMedicationType:
      "An error occurred while deleting the treatment type",
    chooseEventTypeParent: "Select event category",
    intelligentAssistant: "Intelligent Assistant",
    yourMessage: "Your message",
    generalHealthStatus:
      "What is the general health status of the cattle herd?",
    immediateMedicalAttention: "Which cows need immediate medical attention?",
    optimizeFeeding: "How can I optimize feeding to increase milk production?",
    closeToCalving: "Which cows are close to calving?",
    generalHealthStatusPigs:
      "What is the general health status of the pig herd?",
    growthRate: "What is the growth rate of pigs in the last month?",
    pregnantSows: "Which pregnant sows need special attention?",
    weaningStatus: "Which piglets are ready for weaning?",
    diseasePreventionStatus:
      "What is the status of disease prevention measures in the herd?",
    generalHealthStatusGoat:
      "What is the general health status of the goat herd?",
    milkProduction: "How is milk production performing in the recent period?",
    pregnantGoats: "Which pregnant goats need monitoring?",
    kidsHealth: "What is the health status of the kids?",
    generalHealthStatusSheep:
      "What is the general health status of the sheep flock?",
    lambingStatus: "Which ewes are due to lamb in the coming period?",
    lambGrowth: "What is the growth rate of lambs?",
    generalHealthStatusBees:
      "What is the general health status of the bee colonies?",
    honeyProduction:
      "How does honey production compare to the previous period?",
    queenStatus: "What is the status of queens in the colonies?",
    welcomeToAssistant: "Welcome to the Intelligent Assistant!",
    startConversation: "Start the conversation with some of our suggestions.",
    loading: "Loading...",
    UNDER_TREATMENT: "Treatment",
    noResultsForAnimal: "No results found for the specified animal.",
    askAssistant: "Ask the Assistant",
    virtualAssistant: "Virtual Assistant",
    virtualFarmAssistant: "Farm Virtual Assistant",
    farmAdviceWithAI:
      "Personalized farm advice and information with new integrated AI chat.",
    noAnimalsFoundForFilter: "No animals found for the selected filter",
    gestationCalendars: "Gestation calendars",
    activityCalendar: "Activity Calendar",
    inseminationDate: "Insemination date",
    possibleCalvingInterval: "Probable calving interval",
    calvingDate: "Calving date",
    openCalendar: "Open Calendar",
    seeEvents: "Show events",
    hideEvents: "Hide events",
    thisFeatureIsNotEnabled: "This module is not active",
    buyFeature: "Purchase the module",
    noGestationCalendarsPresent: "You have no gestation calendars",
    filterByAge: "Filter by age",
    currentInterval: "Current interval",
    resetFilter: "Reset filter",
    quickSelection: "Quick selection",
    myApiaries: "My Apiaries",
    myApiary: "My Apiary",
    myHives: "My Hives",
    myQueens: "My Queens",
    addApiary: "Add Apiary",
    addHive: "Add Hive",
    isLoading: "Loading...",
    hiveDetails: "Hive Details",
    apiaryDetails: "Apiary Details",
    hiveImage: "Hive Image",
    hiveCode: "Unique Code/Name",
    hiveType: "Hive Type",
    queenBirthYear: "Queen's Birth Year",
    queenOrigin: "Queen's Origin",
    queen: "Queen",
    framesNumber: "Number of Frames",
    familyPower: "Colony Strength",
    beeCount: "Bee Count",
    broodFramesNumber: "Number of Brood Frames",
    honeyFramesNumber: "Number of Honey Frames",
    emptyFramesNumber: "Number of Empty Frames",
    apiaryName: "Apiary Name",
    apiaryAddress: "Address",
    dsvsa: "DSVSA Registration/Authorization No.",
    nameRequired: "Name is required!",
    nameMinLength: "Minimum name length is 2 characters!",
    addressRequired: "Address is required!",
    addressMinLength: "Minimum address length is 5 characters!",
    addressMaxLength: "Maximum address length is 200 characters!",
    registrationCodeRequired: "Registration code is required!",
    registrationCodeFormat:
      "Registration code can only contain letters, numbers, and hyphens!",
    descriptionMaxLength: "Maximum description length is 1000 characters!",
    creatingApiary: "Creating apiary...",
    apiaryCreated: "Apiary has been created!",
    apiaryCreateError: "An error occurred while creating the apiary",
    updatingApiary: "Updating apiary...",
    deletingApiary: "Deleting apiary...",
    apiaryDeleted: "Apiary has been deleted",
    apiaryUpdated: "Apiary has been updated!",
    apiaryUpdateError: "An error occurred while updating the apiary",
    apiaryDeleteError: "An error occurred while deleting the apiary",
    registrationCode: "Registration Code",
    hivesNumber: "Number of Hives",
    deleteApiary: "Delete apiary",
    apiaries: "Apiaries",
    hives: "Hives",
    deleteApiaryConfirmation: "Are you sure you want to delete the apiary?",
    apiaryWillBeDeleted:
      "The apiary, along with all associated data, including hives, queens, and their events will be deleted",
    hiveManufacturer: "Hive Manufacturer",
    creatingHive: "Creating hive...",
    hiveCreatedSuccessfully: "Hive has been created successfully!",
    errorCreatingHive: "An error occurred while creating the hive!",
    apiary: "Apiary",
    apiaryRequired: "Apiary is required",
    apiaryFormat: "Apiary code can only contain letters, numbers, and hyphen",
    codeRequired: "Hive code is required",
    codeFormat: "Hive code can only contain letters, numbers, and hyphen",
    codeMinLength: "Hive code must be at least 2 characters",
    codeMaxLength: "Hive code cannot exceed 50 characters",
    hiveTypeRequired: "Hive type is required",
    invalidHiveType: "Selected hive type is not valid",
    manufacturerRequired: "Hive manufacturer is required",
    manufacturerMinLength: "Manufacturer name must be at least 2 characters",
    manufacturerMaxLength: "Manufacturer name cannot exceed 100 characters",
    broodFramesRequired: "Number of brood frames is required",
    broodFramesFormat: "Number of brood frames must be a whole number",
    broodFramesRange: "Number of brood frames must be between 0 and 100",
    honeyFramesRequired: "Number of honey frames is required",
    honeyFramesFormat: "Number of honey frames must be a whole number",
    honeyFramesRange: "Number of honey frames must be between 0 and 100",
    emptyFramesRequired: "Number of empty frames is required",
    emptyFramesFormat: "Number of empty frames must be a whole number",
    emptyFramesRange: "Number of empty frames must be between 0 and 100",
    beeCountRequired: "Bee count is required",
    beeCountFormat: "Bee count must be a whole number",
    beeCountRange: "Bee count must be between 0 and 100000",
    addQueen: "Add Queen",
    deleteHive: "Delete hive",
    hiveUpdating: "Updating hive...",
    hiveUpdatedSuccessfully: "Hive has been updated!",
    errorUpdatingHive: "An error occurred while updating the hive",
    deleteHiveConfirmation: "Are you sure you want to delete the hive?",
    hiveWillBeDeleted:
      "The hive, along with all associated data, including the queen and their events will be deleted",
    deletingHive: "Deleting hive...",
    deletingQueen: "Deleting queen...",
    queenDeleted: "Queen has been deleted!",
    hiveDeleted: "Hive has been deleted!",
    queenDeleteError: "An error occurred while deleting the queen",
    hiveDeleteError: "An error occurred while deleting the hive",
    yearOfBirth: "Year of birth",
    queenColor: "Queen color",
    "Own Production": "Own Production",
    Purchased: "Purchased",
    Blue: "Blue",
    Green: "Green",
    Red: "Red",
    Yellow: "Yellow",
    Black: "Black",
    White: "White",
    nameFormat:
      "Queen name can only contain letters, numbers, spaces, and hyphens",
    yearRequired: "Year is required",
    yearFormat: "Year must be in YYYY format (e.g., 2024)",
    yearRange: "Year must be between the last 5 years and current year",
    colorRequired: "Queen color is required",
    invalidColor: "Selected color is not valid",
    originRequired: "Queen origin is required",
    invalidOrigin: "Selected origin is not valid",
    creatingQueen: "Creating queen...",
    queenCreated: "Queen has been created!",
    queenCreateError: "An error occurred while creating the queen",
    hive: "Hive",
    deleteQueen: "Delete queen",
    queenUpdating: "Updating queen",
    queenUpdatedSuccessfully: "Queen has been updated!",
    errorUpdatingQueen: "An error occurred while updating the queen",
    deleteQueenConfirmation: "Are you sure you want to delete the queen?",
    queenWillBeDeleted:
      "The queen, along with all associated data, notes, tasks, and events will be deleted",
    bees: "Bees",
    allApiaries: "All Apiaries",
    searchHive: "Search hive",
    allHiveTypes: "All hive types",
    apiaryWasSuccessfullyAdded: "Apiary has been added successfully!",
    hiveStep:
      "The next step is adding hives to your new apiary, would you like to continue?",
    hiveWasSuccessfullyAdded: "Hive has been added successfully!",
    queenStep:
      "The next step is adding a queen to your new hive, would you like to continue?",
    noHivesFoundForGivenQuery: "No hives found for your search",
    noQueensFoundForGivenQuery: "No queens found for your search",
    allQueens: "All Queens",
    assocApiary: "Associate Apiary",
    assocHive: "Associate Hive",
    noApiariesAssociated: "No apiaries associated",
    noHivesAssociated: "No hives associated",
    oneApiaryAssociated: "One apiary associated",
    oneHiveAssociated: "One hive associated",
    apiariesAssociated: "apiaries associated",
    hivesAssociated: "hives associated",
    generalAnimalWelfare: "What is the general state of animal welfare?",
    diseasePreventionPlan:
      "What is the disease prevention plan for all animals?",
    feedingStrategyGeneral: "Is the feeding strategy optimal for all animals?",
    reproductiveHealthGeneral:
      "What is the reproductive health situation in the herd?",
    udderHealth: "What is the udder health status in cows?",
    heatDetection: "Which cows show signs of heat?",
    pigletMortality: "What is the piglet mortality rate?",
    boarPerformance: "What is the breeding boars' performance?",
    generalHealthStatusGoats:
      "What is the general health status of the goat herd?",
    hoofHealth: "What is the hoof condition in goats?",
    pasturePlanning: "Is the grazing plan efficient?",
    flockBehavior: "Are there changes in flock behavior?",
    sheepSorting: "Which sheep need sorting into different groups?",
    hiveStrength: "What is the strength of bee colonies?",
    varoaStatus: "What is the varroa infestation level?",
    nectarSources: "Are there sufficient nectar sources in the area?",
    biosecurityMeasures: "What biosecurity measures are implemented?",
    selectApiaries: "Select apiaries",
    selectApiary: "Select apiary",
    selectHive: "Select hive",
    selectHives: "Select hives",
    noResultsFound: "No results found",
    hideOtherApiariesAssociated: "Hide other associated apiaries",
    hideOtherHivesAssociated: "Hide other associated hives",
    seeOtherApiariesAssociated: "See other associated apiaries",
    seeOtherHivesAssociated: "See other associated hives",
    noApiaryFound: "No apiary found",
    noQueensAssociated: "No queens associated",
    oneQueenAssociated: "One queen associated",
    queensAssociated: "queens associated",
    selectQueens: "Select queens",
    assocQueen: "Associate Queen",
    theHive: "The Hive",
    addApiaryEvent: "Add Apiary Event",
    noApiarySelected: "No apiary selected",
    noHiveSelected: "No hive selected",
    PestControl: "Pest Control",
    VegetationManagement: "Vegetation Management",
    EquipmentMaintenance: "Equipment Maintenance",
    InfrastructureRepair: "Infrastructure Repair",
    ApiaryMaintenance: "Apiary Maintenance",
    SpringAssessment: "Spring Assessment",
    WinterPreparationCheck: "Winter Preparation Check",
    AuthorityInspection: "Authority Inspection",
    OfficialVeterinaryInspection: "Official Veterinary Inspection",
    ApiaryInspection: "Apiary Inspection",
    PastoralMovement: "Pastoral Movement",
    PermanentLocationChange: "Permanent Location Change",
    ApiaryMovement: "Apiary Movement",
    personName: "Person Name",
    eventTypeRequired: "Event type is required",
    eventTypeFormat:
      "Event type must contain only letters, numbers, and hyphens",
    personNameMaxLength: "Person name cannot exceed 255 characters",
    dateFormat: "Invalid date format",
    notesMaxLength: "Notes cannot exceed 1000 characters",
    creatingApiaryEvent: "Creating apiary event...",
    apiaryEventCreatedSuccessfully:
      "Apiary event has been created successfully!",
    errorCreatingApiaryEvent:
      "An error occurred while creating the apiary event",
    apiariesEvents: "Apiary Events",
    apiaryEvent: "Apiary Event",
    deletingApiaryEvent: "Deleting apiary event...",
    apiaryEventDeleted: "Apiary event has been deleted!",
    apiaryEventDeleteError: "An error occurred while deleting the apiary event",
    ApiaryMovementEventType: "Apiary Movement Events",
    ApiaryInspectionEventType: "Apiary Inspection Events",
    ApiaryMaintenanceEventType: "Apiary Maintenance Events",
    Acacia: "Acacia",
    Linden: "Linden",
    Sunflower: "Sunflower",
    Rapeseed: "Rapeseed",
    "Poly Floral": "Poly Floral",
    selectFloraType: "Select Flora Type",
    apiaryEvents: "Apiary Events",
    apiaryEventsDescription: "Monitor your apiaries' activity",
    hiveEventsDescription: "Monitor your hives' activity",
    noEventsFound: "No events found",
    departureDateRequired: "Departure date is required",
    floraTypeRequired: "Flora type is required",
    partners: "Partners",
    viewOffer: "View Offer",
    allTimes: "All periods",
    selectTimeFrame: "Select period",
    dateTo: "End date",
    dateFrom: "Start date",
    present: "Present",
    selectDateTo: "Select end date",
    selectDateFrom: "Select start date",
    showResults: "Show Results",
    hiveEvents: "Hive Events",
    queenEvents: "Queen Events",
    queenEventsDescription: "Monitor your queens' activity",
    allHives: "All Hives",
    creatingHiveEvent: "Creating hive event...",
    hiveEventCreatedSuccessfully: "Hive event has been created successfully!",
    errorCreatingHiveEvent: "An error occurred while creating the hive event",
    "Hive must have a queen for this operation":
      "Hive must have a queen for this operation",
    ColonyDivision: "Colony Division",
    SwarmPrevention: "Swarm Prevention",
    RemovingSuper: "Removing Super",
    AddingSuper: "Adding Super",
    AddingFrames: "Adding Frames",
    QueenReplacement: "Queen Replacement",
    Feeding: "Feeding",
    HiveManagement: "Hive Management",
    WeightMeasurement: "Weight Measurement",
    BroodFramesCount: "Update Brood Frames Count",
    HoneyFramesCount: "Update Honey Frames Count",
    ColonyStrengthAssessment: "Colony Strength Assessment",
    HiveMeasurement: "Hive Measurement",
    PreventiveTreatment: "Preventive Treatment",
    DiseaseTreatment: "Disease Treatment",
    NosemaTreatment: "Nosema Treatment",
    VarroaTreatment: "Varroa Treatment",
    HiveTreatment: "Hive Treatment",
    WinterCheck: "Winter Check",
    BroodInspection: "Brood Inspection",
    QueenInspection: "Queen Inspection",
    DiseaseCheck: "Disease Check",
    RoutineCheck: "Routine Check",
    HiveInspection: "Hive Inspection",
    TransferToOtherApiary: "Transfer to Other Apiary",
    TransferWithinApiary: "Transfer Within Apiary",
    HiveMovement: "Hive Movement",
    hiveEvent: "Hive Event",
    HiveEquipment: "Hive Equipment",
    AddFrames: "Add Frames",
    RemoveFrames: "Remove Frames",
    AddSuper: "Add Super",
    RemoveSuper: "Remove Super",
    AddBroodBox: "Add Brood Box",
    RemoveBroodBox: "Remove Brood Box",
    deletingHiveEvent: "Deleting hive event...",
    hiveEventDeleted: "Hive event has been deleted!",
    hiveEventDeleteError: "An error occurred while deleting the hive event",
    deleteHiveEvent: "Delete Event",
    deleteHiveEventConfirmation: "Are you sure you want to delete the event?",
    hiveEventWillBeDeleted: "The current event will be deleted if yes.",
    updatingHiveEvent: "Updating hive event...",
    hiveEventUpdated: "Hive event has been updated!",
    hiveEventUpdateError: "An error occurred while updating the hive event",
    HiveMovementEventType: "Hive Movement Events",
    HiveInspectionEventType: "Hive Inspection Events",
    HiveTreatmentEventType: "Hive Treatment Events",
    HiveMeasurementEventType: "Hive Measurement Events",
    HiveEquipmentEventType: "Hive Equipment Events",
    hiveRequired: "Hive is required",
    beeKeepingEvents: "Beekeeping Events",
    pieces: "pieces",
    grams: "grams",
    inHivePosition: "Position in hive",
    addNewLocation: "Add a new location",
    previousLocations: "Previous locations",
    noPreviousLocations: "No previous locations",
    addLocation: "Add Location",
    currentLocation: "Current location",
    noCurrentLocation: "No current location",
    currentPosition: "Current position",
    currentWeight: "Current weight",
    broodBoxCount: "Number of brood boxes",
    superCount: "Number of supers",
    positionMaxLength: "Position maximum length is 255 characters",
    fromAndToPositionMustBeDifferent:
      "Departure and arrival positions must be different",
    "More empty frames are needed":
      "More empty frames are needed for this operation",
    "No supers available to remove": "No supers available to remove",
    "You can't delete more suppers than available":
      "You can't delete more supers than available",
    "No brood boxes available to remove": "No brood boxes available to remove",
    "You can't remove more brood boxes than available":
      "You can't remove more brood boxes than available",
    HiveQueenEventsEventType: "Queen Events",
    HiveQueenEvents: "Queen Event",
    QueenMarking: "Queen Marking",
    QueenClipping: "Queen Wing Clipping",
    QueenCaging: "Queen Caging",
    eventInfoDescription: "Information about event type",
    "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.":
      "Events related to relocating the entire apiary, including permanent location changes and seasonal movements for better foraging opportunities.",
    "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.":
      "Official and routine inspections of the apiary as a whole, including regulatory checks and seasonal assessments.",
    "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.":
      "Maintenance activities for the apiary infrastructure, equipment, and surrounding environment.",
    "Events related to moving individual hives within or between apiaries.":
      "Events related to moving individual hives within or between apiaries.",
    "Regular and specific inspections of individual hives to assess health, strength, and conditions.":
      "Regular and specific inspections of individual hives to assess health, strength, and conditions.",
    "Medical and preventive treatments applied to hives for various health conditions.":
      "Medical and preventive treatments applied to hives for various health conditions.",
    "Measurements and assessments of various hive parameters and colony strength indicators.":
      "Measurements and assessments of various hive parameters and colony strength indicators.",
    "Addition or removal of hive equipment and components.":
      "Addition or removal of hive equipment and components.",
    "Events related to queen bee management and maintenance.":
      "Events related to queen bee management and maintenance.",
    beekeeping: "Beekeeping",
    currentChats: "Current chats",
    whatCanIHelpWith: "What can I help you with?",
    recentChats: "Recent chats",
    suggestedQuestions: "Suggested questions",
    startChat: "Start chat",
    viewAllChats: "View all chats",
    noHivesAvailable: "No hives available",
    noHive: "No hive",
    noQueen: "No queen",
    queenHasNoHiveAssigned: "Queen has no hive assigned",
    queenHasNoHiveAssignedAndNoAvailableHives:
      "Queen has no hive assigned and no hives available",
    removeQueenFromHive: "Remove Queen from Hive",
    removeQueenFromHiveConfirmation:
      "Are you sure you want to remove the queen from the hive?",
    removingQueen: "Removing queen...",
    queenRemovedFromHive: "Queen has been removed from hive!",
    errorRemovingQueenFromHive:
      "An error occurred while removing the queen from hive",
    hiveHasNoQueenAndQueensAvailable:
      "The hive has no queen assigned and there are queens available",
    hiveHasNoQueenAndNoQueensAvailable:
      "The hive has no queen assigned and there are no queens available",
    queenHasNoHiveAndHivesAvailable:
      "The queen has no hive assigned and there are hives available",
    removingThread: "Deleting conversation...",
    threadRemoved: "Conversation has been deleted!",
    errorRemovingThread: "An error occurred while deleting the conversation",
    removeThread: "Delete Conversation",
    deleteThreadConfirmation:
      "Are you sure you want to delete the conversation?",
    allThreads: "All Conversations",
    messageRateLimitExceeded: "Message rate limit exceeded.",
    limitWillBeRenewedIn: "Limit will be renewed in",
    messageSendError: "An error occurred while sending the message",
    youStillHave: "You still have",
    messagesLeftThisMonth: "messages left this month",
    messageLeftThisMonth: "message left this month",
    "Medical and preventive treatments applied to animals for various health conditions and maintenance.":
      "Medical and preventive treatments applied to animals for various health conditions and maintenance.",
    "Recording and tracking of various animal metrics and physical parameters.":
      "Recording and tracking of various animal metrics and physical parameters.",
    "Events related to animal movement between locations, including quarantine and isolation.":
      "Events related to animal movement between locations, including quarantine and isolation.",
    "Events related to breeding, pregnancy, birth, and reproductive health management.":
      "Events related to breeding, pregnancy, birth, and reproductive health management.",
    "Events marking the permanent departure of animals from the facility.":
      "Events marking the permanent departure of animals from the facility.",
    "Miscellaneous animal management events including routine care and observations.":
      "Miscellaneous animal management events including routine care and observations.",
    "Planned end of life for research or other approved purposes.":
      "Planned end of life for research or other approved purposes.",
    "Transfer of animal ownership through commercial transaction.":
      "Transfer of animal ownership through commercial transaction.",
    "Recording of natural or unexpected death of an animal.":
      "Recording of natural or unexpected death of an animal.",
    "Documentation of missing or unaccounted for animals.":
      "Documentation of missing or unaccounted for animals.",
    "Administration of vaccines for disease prevention.":
      "Administration of vaccines for disease prevention.",
    "Medical intervention for diagnosed diseases or conditions.":
      "Medical intervention for diagnosed diseases or conditions.",
    "Administration of antiparasitic medications for internal parasite control.":
      "Administration of antiparasitic medications for internal parasite control.",
    "Medical care for physical injuries or trauma.":
      "Medical care for physical injuries or trauma.",
    "Surgical procedures performed on animals.":
      "Surgical procedures performed on animals.",
    "Monitoring and care following surgical procedures.":
      "Monitoring and care following surgical procedures.",
    "Provision of additional nutrients or dietary supplements.":
      "Provision of additional nutrients or dietary supplements.",
    "Recording of animal body weight for monitoring growth and health.":
      "Recording of animal body weight for monitoring growth and health.",
    "Assessment of animal's physical condition and fat reserves.":
      "Assessment of animal's physical condition and fat reserves.",
    "Movement of animals between different locations or facilities.":
      "Movement of animals between different locations or facilities.",
    "Placement of animals in quarantine for health monitoring.":
      "Placement of animals in quarantine for health monitoring.",
    "Release of animals from quarantine after health clearance.":
      "Release of animals from quarantine after health clearance.",
    "Separation of animals for health or management purposes.":
      "Separation of animals for health or management purposes.",
    "Artificial insemination procedure for breeding.":
      "Artificial insemination procedure for breeding.",
    "Recording of natural breeding events.":
      "Recording of natural breeding events.",
    "Confirmation of pregnancy through testing.":
      "Confirmation of pregnancy through testing.",
    "Documentation of negative pregnancy test results.":
      "Documentation of negative pregnancy test results.",
    "Observation and recording of estrus signs.":
      "Observation and recording of estrus signs.",
    "Documentation of birthing events and outcomes.":
      "Documentation of birthing events and outcomes.",
    "Recording of pregnancy loss events.":
      "Recording of pregnancy loss events.",
    "Beginning of milk production period.":
      "Beginning of milk production period.",
    "End of milk production period.": "End of milk production period.",
    "Routine health screening with positive findings.":
      "Routine health screening with positive findings.",
    "Routine health screening with no concerning findings.":
      "Routine health screening with no concerning findings.",
    "Assessment of hoof condition and health.":
      "Assessment of hoof condition and health.",
    "Planned end of lactation in dairy animals.":
      "Planned end of lactation in dairy animals.",
    "Separation of young animals from maternal feeding.":
      "Separation of young animals from maternal feeding.",
    "Modification of feed type or feeding regime.":
      "Modification of feed type or feeding regime.",
    "Removal of wool or hair coat.": "Removal of wool or hair coat.",
    "Maintenance trimming of hooves.": "Maintenance trimming of hooves.",
    "Application of identification tags.":
      "Application of identification tags.",
    "Surgical or chemical sterilization of male animals.":
      "Surgical or chemical sterilization of male animals.",
    "Removal or prevention of horn growth.":
      "Removal or prevention of horn growth.",
    "Scheduled or emergency veterinary examination.":
      "Scheduled or emergency veterinary examination.",
    "Documentation of animal behavior patterns or changes.":
      "Documentation of animal behavior patterns or changes.",
    tutorialsIntro:
      "We're excited that you've chosen to use our application. To help you get familiar with its features, we've created detailed video tutorials that explain step by step how to use our application.",
    birdsGroups: "Birds Groups",
    newBirdGroup: "New Bird Group",
    groupName: "Group Name",
    Adult: "Adult",
    Juvenile: "Juvenile",
    Hatchling: "Hatchling",
    Mixture: "Mixture",
    birdGroupAdultDescription:
      "Fully mature birds that have completed physical development and reached reproductive age. Typically displays adult plumage and coloring patterns specific to their species.",
    birdGroupJuvenileDescription:
      "Young birds that have left the nest and can fly but haven't reached adult maturity. May show intermediate plumage patterns and continue growing. Usually between 2-12 months depending on species.",
    birdGroupHatchingDescription:
      "Newly hatched birds up to fledgling stage. Generally still in the nest, requiring parental care and feeding. May be partially or fully dependent on parents for survival.",
    birdGroupUnknownDescription:
      "Age cannot be determined due to insufficient visual markers, documentation, or observation constraints.",
    birdGroupMixtureDescription:
      "Group of birds with mixed age categories, including adults, juveniles, and hatchlings.",
    ageGroup: "Age Group",
    birdsCount: "Birds Count",
    birdGroups: "Bird Groups",
    birdsGroupCreated: "Bird group has been created!",
    creatingBirdsGroup: "Creating bird group...",
    errorCreatingBirdsGroup: "An error occurred while creating the bird group",
    name100Characters: "Name cannot exceed 100 characters",
    description500Characters: "Description cannot exceed 500 characters",
    countMin1: "Count must be at least 1",
    birdsSummary: "Birds Summary",
    count: "Count",
    deletingBirdsGroup: "Deleting bird group...",
    birdsGroupDeleted: "Bird group has been deleted!",
    birdsGroupDeleteError: "An error occurred while deleting the bird group",
    deleteBirdsGroupConfirmation:
      "Are you sure you want to delete the bird group?",
    birdsGroupWillBeDeleted: "The bird group will be deleted if yes.",
    updatingBirdsGroup: "Updating bird group...",
    birdsGroupUpdated: "Bird group has been updated!",
    errorUpdatingBirdsGroup: "An error occurred while updating the bird group",
    deleteBirdsGroup: "Delete Bird Group",
    Duck: "Duck",
    Turkey: "Turkey",
    Goose: "Goose",
    Quail: "Quail",
    Ostrich: "Ostrich",
    "Rhode Island Red": "Rhode Island Red",
    "Plymouth Rock": "Plymouth Rock",
    Leghorn: "Leghorn",
    Australorp: "Australorp",
    Orpington: "Orpington",
    Sussex: "Sussex",
    Wyandotte: "Wyandotte",
    Brahma: "Brahma",
    Cochin: "Cochin",
    Silkie: "Silkie",
    "New Hampshire": "New Hampshire",
    Ameraucana: "Ameraucana",
    Marans: "Marans",
    Pekin: "Pekin",
    Muscovy: "Muscovy",
    Runner: "Runner",
    "Khaki Campbell": "Khaki Campbell",
    Cayuga: "Cayuga",
    "Swedish Blue": "Swedish Blue",
    Rouen: "Rouen",
    "Welsh Harlequin": "Welsh Harlequin",
    "Broad Breasted White": "Broad Breasted White",
    "Broad Breasted Bronze": "Broad Breasted Bronze",
    "Bourbon Red": "Bourbon Red",
    Narragansett: "Narragansett",
    "Royal Palm": "Royal Palm",
    "Black Spanish": "Black Spanish",
    "Beltsville Small White": "Beltsville Small White",
    Toulouse: "Toulouse",
    Embden: "Embden",
    African: "African",
    Chinese: "Chinese",
    Pilgrim: "Pilgrim",
    "American Buff": "American Buff",
    Sebastopol: "Sebastopol",
    "African Black": "African Black",
    "Blue Neck": "Blue Neck",
    "Red Neck": "Red Neck",
    "Kenyan Red": "Kenyan Red",
    "Zimbabwe Blue": "Zimbabwe Blue",
    "South African Black": "South African Black",
    "Israeli Blue": "Israeli Blue",
    "Australian Black": "Australian Black",
    selectBirdGroups: "Select bird groups",
    noBirdGroupsAssociated: "No bird groups associated",
    oneBirdGroupAssociated: "One bird group associated",
    birdGroupsAssociated: "bird groups associated",
    assocBirdGroup: "Associate Bird Group",
    NotFoundMessage:
      "The page you're looking for doesn't exist or has been moved.",
    goMainScreen: "Go to main screen",
    birds: "Birds",
    animalGender: "Animal Gender",
    selectHiveType: "Select Hive Type",
    contactInfo:
      "For any related questions or problems, feel free to contact us and we will respond back as soon as possible!",
    appLanguage: "Application Language",
    english: "English",
    romanian: "Romanian",
    selectAppLanguage: "Select Application Language",
    event: "Event",
    birdsGroupsEvents: "Birds Events",
    thereAreNoEventsYet: "There are no events yet",
    selectBirdsGroups: "Select birds group",
    creatingBirdsGroupEvent: "Creating birds group event...",
    birdsGroupEventCreated: "Birds group event has been created!",
    errorCreatingBirdsGroupEvent:
      "An error occurred while creating the birds group event",
    birdsGroupRequired: "Birds group is required",
    HealthEventType: "Health Events",
    Health: "Health",
    HealthCheck: "Health Check",
    MedicationAdministration: "Medication Administration",
    birdsGroup: "Birds Group",
    birdsGroupEvent: "Birds Group Event",
    Cleaning: "Cleaning",
    Observation: "Observation",
    allBirdsGroups: "All Birds Groups",
    selectBirdsGroup: "Select birds group",
    AddHeads: "Add Heads",
    RemoveHeads: "Remove Heads",
    "Add birds into the group.": "Add birds into the group.",
    "Remove birds into the group.": "Remove birds into the group.",
    medicineDescription: "Medicine Description",
    measuringUnit: "Measuring Unit",
    birdsGroupIntendedPurpose: "Intended Purpose",
    eggsCount: "Eggs Count",
    eggsProduction: "Eggs Production",
    totalEggsCount: "Total Eggs Count",
    latestCount: "Latest Count",
    recordedEvents: "Recorded Events",
    recentEvents: "Recent Events",
    TrimmingFeathers: "Trimming Feathers",
    noBirdsGroupsFound: "No birds groups found",
    locationRequired: "Location is required",
    intendedUseRequired: "Intended use is required",
  },
}
