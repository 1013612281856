import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { useGetAllBirdsGroupsEventsQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-api"

export interface BirdsGroupsEventsFilterState {
  birds_group?: string
  date_from?: string
  date_to?: string
  event_type?: string
}

export enum BIRDS_GROUPS_EVENTS_FILTER_ENUM {
  BIRDS_GROUP = "birds_group",
  DATE_FROM = "date_from",
  DATE_TO = "date_to",
  EVENT_TYPE = "event_type",
}

export const useBirdsGroupsEventsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentFilters: BirdsGroupsEventsFilterState = useMemo(
    () => ({
      event_type:
        searchParams.get(BIRDS_GROUPS_EVENTS_FILTER_ENUM.EVENT_TYPE) ||
        undefined,
      birds_group:
        searchParams.get(BIRDS_GROUPS_EVENTS_FILTER_ENUM.BIRDS_GROUP) ||
        undefined,
      date_from:
        searchParams.get(BIRDS_GROUPS_EVENTS_FILTER_ENUM.DATE_FROM) ||
        undefined,
      date_to:
        searchParams.get(BIRDS_GROUPS_EVENTS_FILTER_ENUM.DATE_TO) || undefined,
    }),
    [searchParams],
  )

  const { data = [], isLoading } = useGetAllBirdsGroupsEventsQuery({
    birds_group: currentFilters.birds_group || undefined,
    date_from: currentFilters.date_from,
    date_to: currentFilters.date_to,
    event_type: currentFilters.event_type || undefined,
  })

  const updateFilters = useCallback(
    (filters: Partial<BirdsGroupsEventsFilterState>) => {
      const newParams = new URLSearchParams(searchParams)

      if (filters.birds_group) {
        newParams.set(
          BIRDS_GROUPS_EVENTS_FILTER_ENUM.BIRDS_GROUP,
          filters.birds_group,
        )
      } else {
        newParams.delete(BIRDS_GROUPS_EVENTS_FILTER_ENUM.BIRDS_GROUP)
      }

      if (filters.date_from) {
        newParams.set(
          BIRDS_GROUPS_EVENTS_FILTER_ENUM.DATE_FROM,
          filters.date_from,
        )
      } else {
        newParams.delete(BIRDS_GROUPS_EVENTS_FILTER_ENUM.DATE_FROM)
      }

      if (filters.date_to) {
        newParams.set(BIRDS_GROUPS_EVENTS_FILTER_ENUM.DATE_TO, filters.date_to)
      } else {
        newParams.delete(BIRDS_GROUPS_EVENTS_FILTER_ENUM.DATE_TO)
      }

      if (filters.event_type) {
        newParams.set(
          BIRDS_GROUPS_EVENTS_FILTER_ENUM.EVENT_TYPE,
          filters.event_type,
        )
      } else {
        newParams.delete(BIRDS_GROUPS_EVENTS_FILTER_ENUM.EVENT_TYPE)
      }

      setSearchParams(newParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  return {
    filters: currentFilters,
    updateFilters,
    events: data,
    isLoading,
  }
}
