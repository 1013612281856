import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddEggsIcon } from "@/assets/widgets/add-eggs-icon.tsx"
import { type BirdsGroups } from "@/features/birds-groups/types/birds-groups.ts"
import { useGetAllBirdsGroupsEventsQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-api.ts"
import { useGetAllBirdsGroupsEventTypesQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-types-api.ts"
import { BIRDS_GROUPS_EVENTS_FILTER_ENUM } from "@/features/events/birds-groups-events/hooks/use-birds-groups-events-filter.ts"
import {
  BIRDS_GROUPS_CONSTS,
  BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM,
} from "@/features/events/birds-groups-events/types/birds-groups-events-consts.ts"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import {
  BIRDS_GROUPS_EVENTS_SCREEN_PATH,
  NEW_BIRDS_GROUPS_EVENTS_VIEW,
} from "@/utils/constants/routes.ts"

interface Props {
  birdsGroup: BirdsGroups
}

export const EggProductionWidget: React.FC<Props> = ({ birdsGroup }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: eventTypes } = useGetAllBirdsGroupsEventTypesQuery()
  const eggCountingEvent = eventTypes?.find((event) => {
    return event.name === BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING
  })

  const getNavigationString = () => {
    if (!eggCountingEvent) return NEW_BIRDS_GROUPS_EVENTS_VIEW
    const searchParams = new URLSearchParams()

    searchParams.set(BIRDS_GROUPS_CONSTS.BIRDS_GROUP_ID, birdsGroup.id)
    searchParams.set(
      BIRDS_GROUPS_CONSTS.BIRDS_GROUP_EVENT_ID,
      eggCountingEvent?.id,
    )

    const params = searchParams.toString()
    return `${NEW_BIRDS_GROUPS_EVENTS_VIEW}?${params}`
  }

  const navigationString = getNavigationString()

  const allEggsEventsNavigationString = `${BIRDS_GROUPS_EVENTS_SCREEN_PATH}?${BIRDS_GROUPS_EVENTS_FILTER_ENUM.EVENT_TYPE}=${eggCountingEvent?.id}&${BIRDS_GROUPS_EVENTS_FILTER_ENUM.BIRDS_GROUP}=${birdsGroup.id}`

  const { data } = useGetAllBirdsGroupsEventsQuery({
    birds_group: birdsGroup.id,
  })

  const eggCountingEvents = data?.filter((event) => {
    return (
      event?.event_data?.measurement_type ===
      BIRDS_GROUPS_MEASUREMENT_EVENTS_ENUM.EGGS_COUNTING
    )
  })

  const hasEggsEvents = eggCountingEvents && eggCountingEvents?.length > 0

  const totalEggs =
    eggCountingEvents?.reduce((acc, event) => {
      return acc + Number(event.event_data?.value || 0)
    }, 0) || 0

  const latestEggCount = eggCountingEvents?.[0]?.event_data?.value || 0

  const recentEvents = eggCountingEvents?.slice(0, 5) || []

  return (
    <WidgetCard
      icon={<AddEggsIcon />}
      title={t("eggsProduction")}
      navigateTo={allEggsEventsNavigationString}
      addItemGoTo={hasEggsEvents ? () => navigate(navigationString) : undefined}
    >
      {!hasEggsEvents && (
        <EmptyContentCreateItemButton navigateTo={navigationString} />
      )}
      {hasEggsEvents && (
        <div className="space-y-4">
          <div className="mx-[-16px] space-y-2 bg-gray-50 px-4 py-2 text-body-xs">
            <div className="flex items-center justify-between">
              <span className="text-gray-600">{t("totalEggsCount")}</span>
              <span className="font-medium">{totalEggs}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">{t("latestCount")}</span>
              <span className="font-medium">{latestEggCount}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">{t("recordedEvents")}</span>
              <span className="font-medium">
                {eggCountingEvents?.length || 0}
              </span>
            </div>
          </div>

          <div className="mx-[-16px]">
            <p className={"ml-4"}>
              <span className="text-gray-600">{t("recentEvents")}</span>
            </p>
            <table className="w-full text-sm">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-2 text-left font-semibold text-black">
                    {t("Date")}
                  </th>
                  <th className="px-3 py-2 text-left font-semibold text-black">
                    {t("count")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {recentEvents.map((event) => (
                  <tr key={event.id}>
                    <td className="px-3 py-2 text-gray-600">
                      {format(new Date(event.date), "dd.MM.yyyy")}
                    </td>
                    <td className="px-3 py-2 font-medium">
                      {event.event_data?.value || 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </WidgetCard>
  )
}
