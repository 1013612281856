import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import BirdIcon from "@/assets/svg/bird-icon.svg?react"
import { useGetBirdGroupIcon } from "@/features/birds-groups/hooks/use-get-bird-species.ts"
import { type BirdsGroups } from "@/features/birds-groups/types/birds-groups.ts"
import { BIRDS_GROUPS_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface Props {
  group: BirdsGroups
}

export const BirdsGroupCard: React.FC<Props> = ({ group }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const birdGroupImage = useGetBirdGroupIcon(group)
  const navigateToGroup = () => {
    navigate(`${BIRDS_GROUPS_SCREEN_PATH}/${group.id}`)
  }

  return (
    <div
      onClick={navigateToGroup}
      className={
        "widget-border box-border flex w-full gap-4 bg-white px-4 py-4"
      }
    >
      <img
        className={"h-[100px] w-[100px] rounded-[12px] object-cover"}
        src={birdGroupImage}
        alt={group.name}
      />
      <div className="flex max-w-full flex-col gap-2 overflow-hidden">
        <p className="text-lg font-semibold text-neutral-700">
          {t(group.name)}
        </p>
        <div className={"flex gap-2"}>
          <div
            className={
              "flex w-fit items-center gap-2 rounded bg-neutral-100 px-2 py-1 pl-3 text-neutral-700"
            }
          >
            <p className={"text-body font-semibold"}>{group.count}</p>
            <BirdIcon className="h-[24px] w-[24px] p-1" />
          </div>
          <div
            className={
              "flex w-fit items-center gap-2 rounded bg-neutral-100 px-2 py-1 pr-3 text-neutral-700"
            }
          >
            <p className="text-base text-neutral-700">{t(group.age_group)}</p>
          </div>
        </div>
        <p className="w-full truncate text-sm text-neutral-700">
          {group.description}
        </p>
      </div>
    </div>
  )
}
