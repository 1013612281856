import React from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { FilterScroll } from "@/components/filter-scroll/filter-scroll.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import { useGetBirdsGroupsQuery } from "@/features/birds-groups/api/birds-groups-api.ts"
import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { useGetAllBirdsGroupsEventTypesQuery } from "@/features/events/birds-groups-events/api/birds-groups-events-types-api.ts"
import { type BirdsGroupsEventsFilterState } from "@/features/events/birds-groups-events/hooks/use-birds-groups-events-filter.ts"
import { CommonEventsDrawer } from "@/features/events/common/components/common-events-drawer/common-events-drawer.tsx"
import { DateFilterDrawer } from "@/features/events/common/components/date-filter-drawer/date-filter-drawer.tsx"
import { type CommonEventType } from "@/features/events/common/types/common-event-type.ts"

interface Props {
  filters: BirdsGroupsEventsFilterState
  onBirdsGroupChange: (birdsGroup: string) => void
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
  onSelectedEventTypeChange: (eventType: string) => void
}

export const BirdsGroupEventsFilter: React.FC<Props> = ({
  filters,
  onBirdsGroupChange,
  onSelectedTimeFrameChange,
  onSelectedEventTypeChange,
}) => {
  const { t } = useTranslation()
  const { data: birdsGroups } = useGetBirdsGroupsQuery()
  const { data: birdsGroupsEventTypes } = useGetAllBirdsGroupsEventTypesQuery()
  const eventTypesMap = birdsGroupsEventTypes?.reduce<
    Record<string, CommonEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as CommonEventType }
  }, {})
  const parentEventTypes = useMapEventTypesToParents(eventTypesMap)

  const birdsGroupsOptions = [
    {
      value: "",
      label: t("allBirdsGroups"),
    },
  ].concat(
    birdsGroups?.map((birdsGroup) => ({
      value: birdsGroup.id,
      label: birdsGroup.name,
    })) || [],
  )

  const handleEventTypeChange = (eventType: string | undefined) => {
    onSelectedEventTypeChange(eventType || "")
  }

  const selectedBirdsGroupOption = birdsGroupsOptions.find(
    (option) => option.value === filters.birds_group,
  )

  const selectedDateRange =
    filters.date_from && filters.date_to
      ? {
          from: new Date(filters.date_from),
          to: new Date(filters.date_to),
        }
      : undefined

  return (
    <FilterScroll>
      <SelectionRowDrawer
        multiple={false}
        renderTrigger={
          <DrawerTrigger>
            <SelectPill isActive={!!filters.birds_group}>
              <div>
                {selectedBirdsGroupOption
                  ? t(selectedBirdsGroupOption?.label)
                  : t(birdsGroupsOptions[0].label)}
              </div>
            </SelectPill>
          </DrawerTrigger>
        }
        title={t("selectBirdsGroup")}
        options={birdsGroupsOptions}
        selectedValues={filters.birds_group || ""}
        onSelectionChange={onBirdsGroupChange}
      />
      <CommonEventsDrawer
        eventTypesMap={parentEventTypes}
        fallbackText={t("selectEventType")}
        onEventSelect={handleEventTypeChange}
        selectedValue={filters.event_type}
      />
      <DateFilterDrawer
        selectedTimeFrame={selectedDateRange}
        onSelectedTimeFrameChange={onSelectedTimeFrameChange}
      />
    </FilterScroll>
  )
}
