import { CalendarCog } from "lucide-react"
import React from "react"
import { useTranslation } from "react-i18next"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import AinIcon from "@/assets/icons/farm/ain-icon.tsx"
import BreedIcon from "@/assets/icons/farm/breed-icon.tsx"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import MeatIcon from "@/assets/icons/farm/meat-icon.tsx"
import ChooseSomethingIcon from "@/assets/icons/misc/choose-something-icon.tsx"
import BirdIcon from "@/assets/svg/bird-icon.svg?react"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import HiveDetailsRow from "@/features/bees/components/hive-details/hive-details-row.tsx"
import { useGetBirdSpecies } from "@/features/birds-groups/hooks/use-get-bird-species.ts"
import { type BirdsGroups } from "@/features/birds-groups/types/birds-groups.ts"

interface Props {
  birdsGroup: BirdsGroups
}

export const BirdsGroupDetails: React.FC<Props> = ({ birdsGroup }) => {
  const { t } = useTranslation()
  const birdSpecies = useGetBirdSpecies()
  const selectedSpecies = birdSpecies?.find(
    (species) => species.id === birdsGroup.species,
  )
  const selectedBreed = selectedSpecies?.breeds.find(
    (breed) => breed.id === birdsGroup.breed,
  )

  return (
    <OverflowMenuItem>
      <div className={"text-[16px] font-semibold text-neutral-700"}>
        {t("birdsSummary")}
        <div
          className={
            "w-[calc(100% + 16px)] my-[10px] ml-0 mr-[-16px] h-[1px] bg-neutral-100"
          }
        ></div>
      </div>

      <HiveDetailsRow
        iconClassName={"bg-[var(--done-green)]"}
        text={t("name")}
        value={birdsGroup.name}
      >
        <AinIcon />
      </HiveDetailsRow>

      {selectedSpecies ? (
        <HiveDetailsRow text={t("species")} value={selectedSpecies.name}>
          <ChooseSomethingIcon />
        </HiveDetailsRow>
      ) : (
        <Skeleton className={"h-[30px] w-full rounded"} />
      )}

      {selectedBreed ? (
        <HiveDetailsRow
          iconClassName={"bg-transparent"}
          text={t("breed")}
          value={selectedBreed.name}
        >
          <BreedIcon />
        </HiveDetailsRow>
      ) : (
        <Skeleton className={"h-[30px] w-full rounded"} />
      )}

      <HiveDetailsRow
        text={t("birdsGroupIntendedPurpose")}
        value={birdsGroup.intended_use}
      >
        <div
          className={
            "grid h-[30px] w-[30px] place-content-center rounded bg-red-500"
          }
        >
          <MeatIcon className={"text-white"} />
        </div>
      </HiveDetailsRow>

      <HiveDetailsRow text={t("ageGroup")} value={t(birdsGroup.age_group)}>
        <div
          className={
            "grid h-[30px] w-[30px] place-content-center rounded bg-brown-500"
          }
        >
          <CalendarCog className={"h-[20px] w-[20px] text-white"} />
        </div>
      </HiveDetailsRow>

      <HiveDetailsRow
        text={t("location")}
        value={birdsGroup.location}
        iconClassName={"bg-transparent"}
      >
        <LocationIcon />
      </HiveDetailsRow>

      <HiveDetailsRow text={t("count")} value={String(birdsGroup.count)}>
        <BirdIcon className="h-[30px] w-[30px] rounded bg-amber-500 p-1 text-white" />
      </HiveDetailsRow>

      {birdsGroup.description && (
        <HiveDetailsRow
          text={t("details")}
          value={birdsGroup.description}
          enableExpand={true}
          iconClassName={"bg-[var(--menu-financial-journal-color)]"}
        >
          <DetailsIcon className={"scale-150"} />
        </HiveDetailsRow>
      )}
    </OverflowMenuItem>
  )
}
