import {
  type AGE_GROUPS,
  BIRDS_SPECIES_ENUM,
  type BIRDS_SPECIES_ENUM_TYPE,
  type BirdsGroups,
} from "@/features/birds-groups/types/birds-groups.ts"
import {
  getBirdsGroupsDefaultImage,
  getBirdsGroupsSpeciesDefaultImage,
} from "@/features/birds-groups/utils/birds-groups-utils.ts"
import { useGetSpeciesQuery } from "@/features/farm/api/species-api.ts"

const BIRDS_SPECIES = Object.values(BIRDS_SPECIES_ENUM)

export const useGetBirdSpecies = () => {
  const { data: species_breeds } = useGetSpeciesQuery()
  const speciesBreedsArray =
    (species_breeds && Object.values(species_breeds)) || []

  return speciesBreedsArray?.filter((species) =>
    BIRDS_SPECIES.includes(species.name),
  )
}

export const useGetBirdGroupSpeciesName = (speciesId: string) => {
  const { data: species_breeds } = useGetSpeciesQuery()
  const speciesBreedsArray =
    (species_breeds && Object.values(species_breeds)) || []

  const species = speciesBreedsArray.find((species) => species.id === speciesId)

  return species?.name
}

export const useGetBirdGroupIcon = (group: BirdsGroups) => {
  const speciesName = useGetBirdGroupSpeciesName(group.species)

  if (group.picture) {
    return group.picture
  }
  if (speciesName === BIRDS_SPECIES_ENUM.CHICKEN) {
    return getBirdsGroupsDefaultImage(group.age_group as AGE_GROUPS)
  } else {
    return getBirdsGroupsSpeciesDefaultImage(
      speciesName as BIRDS_SPECIES_ENUM_TYPE,
    )
  }
}
