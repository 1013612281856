import { useTranslation } from "react-i18next"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { ApiaryEventsSummaryCard } from "@/features/events/common/components/apiary-events-summary-card/apiary-events-summary-card.tsx"
import { HiveEventsSummaryCard } from "@/features/events/common/components/hive-events-summary-card/hive-events-summary-card.tsx"

const BeeKeepingEvents = () => {
  const { t } = useTranslation()

  return (
    <>
      <MobileTopBar title={t("beeKeepingEvents")} />
      <PageContentWrapperLarge className={"flex flex-col"}>
        <ApiaryEventsSummaryCard />
        <HiveEventsSummaryCard />
      </PageContentWrapperLarge>
    </>
  )
}

export default BeeKeepingEvents
