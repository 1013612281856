import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"

export const useGetHiveEventTypesMap = () => {
  const { data: hivesEventTypes } = useGetAllHiveEventTypesQuery()

  const eventTypesMap = hivesEventTypes?.reduce<
    Record<string, AnimalEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})

  return useMapEventTypesToParents(eventTypesMap)
}

export const useGetApiariesEventTypesMap = () => {
  const { data: apiariesEventTypes } = useGetAllApiaryEventTypesQuery()
  const eventTypesMap = apiariesEventTypes?.reduce<
    Record<string, AnimalEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})

  return useMapEventTypesToParents(eventTypesMap)
}
