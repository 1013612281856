import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Medication } from "@/features/events/common/types/common-events-types.ts"

const BIRDS_MEDICATION_TYPES_BASE_URL = "/birds-medications/"

export const BirdsMedicationTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBirdsMedicationTypes: builder.query<Record<string, Medication>, void>(
      {
        query: () => ({
          url: BIRDS_MEDICATION_TYPES_BASE_URL,
        }),
        transformResponse: (response: Medication[]) => {
          return response.reduce<Record<string, Medication>>(
            (acc, eventType) => {
              return { ...acc, [eventType.id]: eventType }
            },
            {},
          )
        },
        providesTags: () => [{ type: TagType.MedicationTypes, id: "LIST" }],
      },
    ),
  }),
})

export const { useGetAllBirdsMedicationTypesQuery } = BirdsMedicationTypesApi
