import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const AddEventIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width="62"
      height="30"
      viewBox="0 0 62 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="60.5"
        height="28.5"
        rx="14.25"
        stroke="#B5642D"
        stroke-width="1.5"
      />
      <path
        d="M13 14.6667H26.3333M19.6667 8V21.3333"
        stroke="#B5642D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip0_3503_7384)">
        <path
          d="M47.7813 24.6875C46.7429 24.6875 45.7279 24.3796 44.8645 23.8027C44.0012 23.2258 43.3282 22.4059 42.9309 21.4466C42.5335 20.4873 42.4296 19.4317 42.6321 18.4133C42.8347 17.3949 43.3347 16.4594 44.0689 15.7252C44.8032 14.991 45.7386 14.491 46.757 14.2884C47.7754 14.0858 48.831 14.1898 49.7903 14.5871C50.7497 14.9845 51.5696 15.6574 52.1465 16.5208C52.7233 17.3841 53.0313 18.3992 53.0313 19.4375C53.0313 20.8299 52.4781 22.1652 51.4936 23.1498C50.509 24.1344 49.1736 24.6875 47.7813 24.6875ZM47.7813 15.5C47.0025 15.5 46.2412 15.7309 45.5937 16.1636C44.9462 16.5962 44.4415 17.2112 44.1435 17.9307C43.8455 18.6502 43.7675 19.4419 43.9194 20.2057C44.0713 20.9695 44.4463 21.6711 44.997 22.2217C45.5477 22.7724 46.2493 23.1474 47.0131 23.2993C47.7769 23.4513 48.5686 23.3733 49.2881 23.0753C50.0076 22.7773 50.6225 22.2726 51.0552 21.6251C51.4878 20.9775 51.7188 20.2163 51.7188 19.4375C51.7188 18.3932 51.3039 17.3917 50.5655 16.6533C49.8271 15.9148 48.8255 15.5 47.7813 15.5Z"
          fill="#B5642D"
        />
        <path
          d="M48.8247 21.4062L47.125 19.7066V16.8125H48.4375V19.1684L49.75 20.4809L48.8247 21.4062Z"
          fill="#B5642D"
        />
        <path
          d="M52.375 8.9375C52.375 8.5894 52.2367 8.25556 51.9906 8.00942C51.7444 7.76328 51.4106 7.625 51.0625 7.625H48.4375V6.3125H47.125V7.625H41.875V6.3125H40.5625V7.625H37.9375C37.5894 7.625 37.2556 7.76328 37.0094 8.00942C36.7633 8.25556 36.625 8.5894 36.625 8.9375V22.0625C36.625 22.4106 36.7633 22.7444 37.0094 22.9906C37.2556 23.2367 37.5894 23.375 37.9375 23.375H40.5625V22.0625H37.9375V8.9375H40.5625V10.25H41.875V8.9375H47.125V10.25H48.4375V8.9375H51.0625V12.875H52.375V8.9375Z"
          fill="#B5642D"
          stroke="#B5642D"
          stroke-width="0.4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3503_7384">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(34 5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
