import React from "react"
import { useTranslation } from "react-i18next"
import { BirdsCounter } from "@/features/birds-groups/components/birds-counter/birds-counter.tsx"
import {
  BirdsCounterIconSet,
  EggsCounterIconSet,
} from "@/features/birds-groups/components/birds-counter/counter-icon-sets.tsx"
import { type BirdsGroupEventInput } from "@/features/events/birds-groups-events/types/birds-groups-events.ts"

interface Props {
  draftEvent: BirdsGroupEventInput
  onValueChange: (value: string) => void
  countType: "heads" | "eggs"
}

export const BirdsGroupEventsMeasurementEditor: React.FC<Props> = ({
  draftEvent,
  onValueChange,
  countType = "heads",
}) => {
  const { t } = useTranslation()

  const handleValueChange = (value: number) => {
    onValueChange(String(value))
  }

  const iconSet =
    countType === "heads" ? <BirdsCounterIconSet /> : <EggsCounterIconSet />

  return (
    <>
      <div className={"mt-2 text-[14px] text-neutral-400"}>
        {t("specificEventDetails")}
      </div>
      <BirdsCounter
        iconSet={iconSet}
        count={Number(draftEvent.event_data?.value) || 0}
        onCountChange={handleValueChange}
      />
    </>
  )
}
